import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { OrderlayoutService } from 'services/orderlayout.service';
import { OrderService } from 'services/order.service';
import { OrderPaymentDisplay, ScheduledPaymentDisplay } from '@taradel/admin-api-client';
import { ToastService } from 'services/toast.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'services/authentication.service';


@Component({
  selector: 'app-order-payments',
  templateUrl: './order-payments.component.html',
  styleUrls: ['./order-payments.component.scss']
})

export class OrderPaymentsComponent implements OnInit, OnDestroy {
	loading = false;
	orderPayments: OrderPaymentDisplay[] | undefined;
	selectedOrderPayment?: OrderPaymentDisplay;
	scheduledPayments?: ScheduledPaymentDisplay[];

	orderLoaded$ = new Subscription();
	@ViewChild('paymentType') paymentType!: ElementRef;

	constructor(
		public orderLayoutService: OrderlayoutService,
		public orderService: OrderService,
		public authService: AuthenticationService,
		private modalService: NgbModal,
		private toastService: ToastService) {
	}

	ngOnInit(): void {
		this.orderLoaded$ = this.orderLayoutService.selectedOrderLoaded.subscribe(async response => {
			this.orderPayments = this.orderLayoutService.orderPayments;
			if (!!response) {
				this.scheduledPayments = await this.orderService.getScheduledPaymentsForOrder(response.orderId!);
			}
		});
	}

    paymentHasBalance(customerPaymentId: number) {
        const paidAmount = this.orderPayments?.filter(p => p.customerPaymentId === customerPaymentId).reduce((a, b) => {
			return a + b.amount;
		}, 0) ?? 0;
        return paidAmount > 0;
    }
	ngOnDestroy(): void {
		this.orderLoaded$.unsubscribe();
	}

	openModal(modal: any, payment: OrderPaymentDisplay) {
		this.selectedOrderPayment = payment;
		this.modalService.open(modal);
	}

	closeModal() {
		this.modalService.dismissAll();
	}
	async deleteOrderPayment() {
		try {
			this.closeModal();
			this.loading = true;
			await this.orderService.deleteOrderPayment(this.selectedOrderPayment?.orderPaymentId!);
			this.orderPayments = await this.orderService.getOrderPayments(this.selectedOrderPayment?.orderId!);
			this.toastService.showSuccess('Order Payment deleted successfully');
		}
		catch (error: any) {
			if (error.status === 400) {				
				this.toastService.showError(JSON.parse(error.response));
			}
			else {
				this.toastService.showError('There was an error deleting the order payment');
			}
		}
		finally {
			this.loading = false;
		}
	}
}
