<app-spinner-with-overlay
	[showSpinner]="orderLayoutService.loadingOrder || orderLayoutService.loadingOrderItem || orderLayoutService.loadingPO || loading">
	<app-alert-box alertType="info"
		*ngIf="!orderLayoutService.loadingOrder && !orderLayoutService.loadingOrderItem && !orderLayoutService.purchaseOrder && !orderLayoutService.isDesignTeam">
		Pending purchase order creation</app-alert-box>

	<app-alert-box alertType="info"
		*ngIf="!orderLayoutService.isTaradel && !orderLayoutService.loadingOrder && !orderLayoutService.loadingOrderItem && !orderLayoutService.purchaseOrder">
		Pending purchase order creation</app-alert-box>

	<div class="card bg-gray-300" *ngIf="orderLayoutService.isDesignTeam || orderLayoutService.purchaseOrder">
		<!-- Create Purchase Order Form -->
		<form [formGroup]="poForm"
			*ngIf="!orderLayoutService.loadingPO && !orderLayoutService.purchaseOrder && orderLayoutService.isDesignTeam && orderLayoutService.isTaradel">
			<div class="card-body">
				<h5 class="card-title">Purchase Order</h5>
				<app-vendor-recommendation (printerSet)="setPrinter($event)"></app-vendor-recommendation>
				<app-eligible-printers [printerType]="printerType" (affiliateOverride)="overrideAffiliate()"
					(overrideCancel)="cancelOverride()" (selectPrinterType)="printerTypeSelected($event)">
				</app-eligible-printers>
				<div *ngIf="orderLayoutService.eligiblePrinterResponse !== undefined">
					<div class="row d-flex align-items-center mb-3" *ngIf="overrideAllowed">
						<div class="col-md-6">
							<div class="form-floating">
								<textarea class="font-20" type="text" formControlName="overrideAffiliateReason"
									class="form-control" placeholder="Reason of Override"
									aria-label="Reason of Override" style="height:70px;"></textarea>
								<label for="overrideAffiliateReason">Reason for Override</label>
							</div>
							<div *ngIf="poForm.controls.overrideAffiliateReason.errors" class="invalid-feedback">
								Reason for overriding affiliate is required.
							</div>
						</div>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-md-7">
						<div class="form-floating">
							<select type="select" formControlName="vendor" class="form-select"
								[attr.disabled]="(orderLayoutService.eligiblePrinterResponse === undefined || orderLayoutService.isEligiblePrinterTaradel) ? null: 'disabled'"
								[ngClass]="{ 'is-invalid': poForm.controls.vendor.errors }">
								<option *ngFor="let x of printers" value="{{x.printerId}}">
									{{x.companyName}} ({{x.printerId}})
								</option>
							</select>
							<label for="printer">Vendor</label>
						</div>
						<div *ngIf="poForm.controls.vendor.errors" class="invalid-feedback">
							Choose a vendor to fulfill this job.
						</div>
					</div>
					<div class="col-md-5">
						<div class="form-floating">
							<input type="date" class="form-control" name="deliveryDate" formControlName="deliveryDate"
								[ngModel]="getFirstDeliveryDate() | date:'yyyy-MM-dd'" max="9999-12-31"
								[ngClass]="{ 'is-invalid': poForm.controls.deliveryDate.errors }">
							<label for="deliveryDate">Delivery Date</label>
						</div>
						<div *ngIf="poForm.controls.deliveryDate.errors" class="invalid-feedback">
							Enter a valid delivery date for this job.
						</div>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col">
						<div class="form-check form-check-inline">
							<input class="form-check-input" type="checkbox" formControlName="allowEarlierDate"
								(change)="revalidateDate()">
							<label class="form-check-label">Allow Earlier Date Selection</label>
						</div>
					</div>
				</div>
				<div class="row d-flex align-items-center mb-3">
					<div class="col-md-6">
						<div class="form-floating">
							<input type="number" formControlName="vendorRate" class="form-control"
								[ngClass]="{ 'is-invalid': poForm.controls.vendorRate.errors }"
								placeholder="Vendor Rate" step="0.01" min="0">
							<label for="vendorRate">Vendor Rate/thousand</label>
						</div>
						<div *ngIf="poForm.controls.vendorRate.errors" class="invalid-feedback">
							Enter the rate / thousand for this job to be fulfilled by the selected
							vendor.
						</div>
					</div>
					<div class="col-md-6">
						<b>Vendor Total:</b> {{jobCost | currency}}
					</div>
				</div>

				<div class="form-floating">
					<textarea formControlName="comments" class="form-control" style="height:100px;"
						placeholder="Comments"></textarea>
					<label for="comments">Comments</label>
				</div>
			</div>
			<div class="card-body">
				<app-alert-box *ngIf="createPoMsg && createPoMsg !== ''" alertMode="danger">
					{{createPoMsg}}
				</app-alert-box>
				<button class="btn btn-primary" [disabled]="submitted || poForm.invalid" (click)="createPo()">Create
					Purchase Order</button>
			</div>
		</form>

		<!-- Existing Purchase Order -->
		<form [formGroup]="editPoForm" *ngIf="!orderLayoutService.loadingPO && orderLayoutService.purchaseOrder">
			<div class="card-body">
				<h5 class="card-title d-flex align-items-center justify-content-between">
					<span>Purchase Order</span>
					<button class="btn btn-link" *ngIf="orderLayoutService.isTaradel" (click)="downloadPo()"><i class="fas fa-download"></i> Download
						TAR{{orderLayoutService.purchaseOrder.purchaseOrderId}}</button>
				</h5>
				<app-vendor-recommendation *ngIf="orderLayoutService.isTaradel" (printerSet)="setPrinter($event)"></app-vendor-recommendation>
				<div class="row mb-3">
					<div class="col-md-4">
						<div class="form-floating">
							<input type="text" class="form-control" disabled
								[value]="'TAR' + orderLayoutService.purchaseOrder.purchaseOrderId" />
							<label>PO Number</label>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-floating">
							<input type="text" class="form-control" disabled
								[value]="orderLayoutService.purchaseOrder.createdDate | date:'short'" />
							<label>Created Date</label>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-floating">
							<input type="date" class="form-control" id="deliveryDate" max="9999-12-31"
								formControlName="deliveryDate" />
							<label>Delivery Date</label>
						</div>
						<div *ngIf="editPoForm.controls.deliveryDate.errors" class="invalid-feedback">
							Enter a valid delivery date for this job.
						</div>
					</div>
				</div>
				<div *ngIf="editPoForm.controls.allowEarlierDate.enabled" class="row mb-3">
					<div class="col">
						<div class="form-check form-check-inline">
							<input class="form-check-input" type="checkbox" formControlName="allowEarlierDate"
								(change)="revalidateDate()">
							<label class="form-check-label">Allow Earlier Date Selection</label>
						</div>
					</div>
				</div>

				<div class="row mb-3" *ngIf="editPo">
					<app-eligible-printers [printerType]="printerType" [edit]="true"
						(affiliateOverride)="overrideAffiliate()" (overrideCancel)="cancelOverride()"
						(selectPrinterType)="printerTypeSelected($event)"></app-eligible-printers>
					<div *ngIf="overrideAllowed && orderLayoutService.eligiblePrinterResponse !== undefined">
						<div class="row d-flex align-items-center mb-3" *ngIf="overrideAllowed">
							<div class="col-md-6">
								<div class="form-floating">
									<textarea class="font-20" type="text" formControlName="overrideAffiliateReason"
										class="form-control" placeholder="Reason of Override"
										aria-label="Reason of Override" style="height:70px;"></textarea>
									<label for="overrideAffiliateReason">Reason for Override</label>
								</div>
								<div *ngIf="submitted && editPoForm.controls.overrideAffiliateReason.errors"
									class="invalid-feedback">
									Reason for overriding affiliate is required.
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-7" *ngIf="orderLayoutService.isTaradel">
						<div class="form-floating">
							<select type="select" formControlName="vendor" class="form-select"
								[attr.disabled]="(orderLayoutService.eligiblePrinterResponse === undefined || orderLayoutService.isEligiblePrinterTaradel) ? null: 'disabled'"
								[ngClass]="{ 'is-invalid': submitted && poForm.controls.vendor.errors }">
								<option *ngFor="let x of printers" value="{{x.printerId}}">
									{{x.companyName}} ({{x.printerId}})
								</option>
							</select>
							<label for="printer">Vendor</label>
						</div>
						<div *ngIf="submitted && editPoForm.controls.vendor.errors" class="invalid-feedback">
							Choose a vendor to fulfill this job.
						</div>
					</div>
				</div>
				<div class="row d-flex align-items-center mb-3">
					<div class="col-md-6">
						<div *ngIf="!editPo && orderLayoutService.isTaradel" class="form-floating mb-3">
							<div class="form-floating">
								<input type="text" class="form-control" [value]="orderLayoutService.purchaseOrderVendorName"
									readonly disabled />
								<label>Vendor</label>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div *ngIf="!editPo" class="form-floating mb-3">
							<div class="form-floating">
								<input type="text" class="form-control" [value]="orderLayoutService.purchaseOrder.status ?? 'Created'"
									readonly disabled />
								<label>Status</label>
							</div>
						</div>
					</div>
				</div>
				<div class="row d-flex align-items-center mb-3" *ngIf="orderLayoutService.isTaradel">
					<div class="col-md-6">
						<div class="form-floating">
							<input type="number" class="form-control" formControlName="vendorRate"
								[value]="orderLayoutService.purchaseOrder.printerRatePerM">
							<label for="vendorRate">Vendor Rate</label>
						</div>
						<div *ngIf="editPoForm.controls.vendorRate.errors" class="invalid-feedback">
							Enter the rate / thousand for this job to be fulfilled by the selected
							vendor.
						</div>
					</div>
					<div class="col-md-6">
						<b>Vendor Total:</b> {{jobCost | currency}}
					</div>
				</div>
			</div>
			<div class="card-body" *ngIf="orderLayoutService.isDesignTeam && orderLayoutService.isTaradel">
				<ul class="list-inline" *ngIf="!poResendCooldown">
					<li class="list-inline-item" *ngIf="!editPo && orderLayoutService.purchaseOrderStatus?.allowResend && orderLayoutService.purchaseOrder?.status !== 'Cancelled'">
						<button class="btn btn-primary" [disabled]="loading"
							(click)="modalService.open(confirmResend)">Resend Purchase
							Order</button>
					</li>
					<li class="list-inline-item" *ngIf="!editPo && orderLayoutService.purchaseOrderStatus?.allowChange">
						<button class="btn btn-warning" [disabled]="loading" (click)="setModifyPo()">Change PO</button>
					</li>
					<li class="list-inline-item" *ngIf="orderLayoutService.purchaseOrder?.status !== 'Cancelled'">
						<button class="btn btn-warning" (click)="modalService.open(confirmCancel)"
							[disabled]="loading">Cancel PO</button>
					</li>
					<li class="list-inline-item" *ngIf="editPo">
						<button class="btn btn-link" (click)="cancelPoUpdate()">Cancel</button>
					</li>
					<li class="list-inline-item" *ngIf="editPo">
						<app-alert-box *ngIf="updatePoMsg && updatePoMsg !== ''" alertMode="danger">
							{{updatePoMsg}}
						</app-alert-box>
						<button class="btn btn-primary" [disabled]="editPoForm.invalid || loading"
							(click)="updatePo()">Save
							Changes</button>
					</li>
				</ul>
				<div *ngIf="poResendCooldown">
					Please wait at least 10 minutes before attempting to resend the purchase order. This allows for the
					automated sending process to complete.
				</div>
			</div>
			<div class="card-body" *ngIf="orderLayoutService.purchaseOrderStatus && orderLayoutService.isTaradel">
				<div class="row">
					<div class="col ms-auto">
						<div class="form-floating">
							<input type="text" class="form-control" formControlName="vendorReference">
							<label for="vendorRate">Vendor Refence</label>
						</div>
					</div>
					<div class="col">
						<p class="mb-1"><button class="btn btn-primary btn-xs" (click)="updateVendorReference()"
								[disabled]="loading">Update Vendor Reference</button></p>
						<p *ngIf="orderLayoutService.purchaseOrderStatus!.metaItems?.length">
							<button class="btn btn-info btn-xs" data-bs-toggle="offcanvas" href="#orderMetaOffCanvas"
								role="button" aria-controls="orderMetaOffCanvas">Order Meta</button>
						</p>
					</div>
				</div>
				<ul *ngIf="imbSerialStatuses.length" class="list-unstyled">
					<li *ngFor="let status of imbSerialStatuses">
						<b>{{ status.statusId }}:</b> {{ status.statusMessage }}
					</li>
				</ul>
				<ul class="list-unstyled">
					<li *ngFor="let status of purchaseOrderStatuses; let first = first"
						[ngClass]="{'text-success': first}">
						<span *ngIf="first">now in </span>
						<span *ngIf="!first">was in </span>
						<b>{{status.statusMessage}}</b>
						<span *ngIf="first"> since </span>
						<span *ngIf="!first"> on </span>
						{{ status.statusDate }}
					</li>
				</ul>
				<ul class="list-unstyled" *ngIf="orderMailTracking">
					<b>Mail Tracking</b>
					<li>
						<span>Being Delivered: {{orderMailTracking.beingDelivered}}</span>
					</li>
					<li>
						<span>En Route: {{orderMailTracking.enRoute}}</span>
					</li>
					<li>
						<span>Marked for Return: {{orderMailTracking.markedForReturn}}</span>
					</li>
					<li>
						<span>Near Destination: {{orderMailTracking.nearDestination}}</span>
					</li>
					<li>
						<span>Trackable: {{orderMailTracking.trackable}}</span>
					</li>
					<li>
						<span>USPS Cancelled: {{orderMailTracking.uspsCancelled}}</span>
					</li>
					<li>
						<span>USPS File Date: {{orderMailTracking.uspsFileDate}}</span>
					</li>
					<li>
						<span>USPS Received: {{orderMailTracking.uspsReceived}}</span>
					</li>
				</ul>
			</div>
			<div class="card-body"
				*ngIf="!orderLayoutService.purchaseOrderStatus && orderLayoutService.purchaseOrderStatusMessage.length > 0">
				<div class="row">
					<div class="col">
						<b class="invalid-feedback">{{ orderLayoutService.purchaseOrderStatusMessage }}</b>
					</div>
				</div>
			</div>
		</form>
	</div>
</app-spinner-with-overlay>

<ng-template #confirmResend let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Confirm Purchase Order Re-Send</h4>
		<button type="button" class="btn-close" aria-label="Close" [disabled]="loading"
			(click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<app-alert-box *ngIf="resendMsg" alertMode="danger">
			{{resendMsg}}
		</app-alert-box>

		<p>This option will resend the purchase order to the vendor on the associated purchase order.</p>
		<p>Please note that completing this action could result in the vendor producing the order a second time.</p>
		<p>Confirm with the vendor after re-send to ensure that this does not happen.</p>
	</div>
	<div class="modal-footer">
		<button class="btn btn-link" [disabled]="loading" (click)="modal.dismiss('Cancel Click')">Cancel</button>
		<button type="button" class="btn btn-primary" [disabled]="loading"
			(click)="resendPurchaseOrder()">Resend</button>
	</div>
</ng-template>

<ng-template #confirmCancel let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Confirm Purchase Order Cancel</h4>
		<button type="button" class="btn-close" aria-label="Close" [disabled]="loading"
			(click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<p>By canceling this PO you are confirming <strong>that no vendor costs have been incurred</strong> from the
			vendor.
			Only proceed if there are <strong>no vendor costs whatsoever.</strong></p>
	</div>
	<div class="modal-footer">
		<button class="btn btn-link" [disabled]="loading" (click)="modal.dismiss('Cancel Click')">Cancel</button>
		<button type="button" class="btn btn-primary" [disabled]="loading" (click)="cancelPo()">Cancel PO</button>
	</div>
</ng-template>

<div class="offcanvas offcanvas-end" id="orderMetaOffCanvas">
	<div class="offcanvas-header">
		<h5 class="offcanvas-title">Order Meta</h5>
		<button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
	</div>
	<div class="offcanvas-body">
		<div *ngFor="let meta of orderLayoutService.purchaseOrderStatus?.metaItems">
			<button class="btn btn-link" data-bs-toggle="collapse"
				attr.data-bs-target="#collapseMeta{{meta.orderMetaId}}" role="button" aria-expanded="false"
				attr.aria-controls="collapseMeta{{meta.orderMetaId}}">{{ meta.metaName }}</button>
			<div class="collapse" id="collapseMeta{{meta.orderMetaId}}">
				<pre><code>{{ meta.metaValue | prettyPrintJson }}</code></pre>
			</div>
		</div>
	</div>
</div>
