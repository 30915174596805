import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiException, CustomerProperty } from '@taradel/web-api-client';
import { OrderlayoutService } from 'services/orderlayout.service';
import { SalesApiService } from "services/sales-api.service";
import { ToastService } from 'services/toast.service';
import { CustomerService } from 'services/customer.service';
import { environment } from 'environment';
import { AuthenticationService } from 'services/authentication.service';
import { DeleteOrderQuery, PaymentAction, PaymentType } from '@taradel/admin-api-client';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-order-summary',
	templateUrl: './order-summary.component.html',
	styleUrls: ['./order-summary.component.scss']
})

export class OrderSummaryComponent implements OnInit, OnDestroy {

	loading = false;
	submitted = false;
	isCanada = environment.instance === 'Canada';
	ventureOneNumberInvalid = false;
	hasOtherPaymentTypes = false;
	canRefund = false;
	deleteReason ='';
	otherReason = '';
	paymentAction?: PaymentAction;
	orderLoaded$ = new Subscription();

	constructor(
		public authService: AuthenticationService,
		public orderLayoutService: OrderlayoutService,
		public salesApiService: SalesApiService,
		public modalService: NgbModal,
		private toastService: ToastService,
		private customerService: CustomerService
	) { }

	ngOnInit(): void {
		this.orderLoaded$ = this.orderLayoutService.selectedOrderLoaded.subscribe(response => {
			this.hasOtherPaymentTypes = this.orderLayoutService.orderPayments.some(op => op.customerPayment?.paymentType !== PaymentType.CreditCard && op.customerPayment?.paymentType !== PaymentType.ACH);
			const today = new Date();
			const payments = this.orderLayoutService.orderPayments.reduce((acc, op) => {
				// Initialize an array for the CustomerPaymentId if it doesn't exist
				if (!acc[op.customerPaymentId]) {
				acc[op.customerPaymentId] = 0;
				}
				// Push the current object to the group
				acc[op.customerPaymentId] = acc[op.customerPaymentId] + op.amount;

				return acc;
			}, {} as Record<number, number>);

			// any order with payments created today with partial amount used for the order
			this.canRefund = !this.orderLayoutService.orderPayments.some(x =>
				x.amount !== 0 && !!x.customerPayment?.createdDate &&
				x.customerPayment?.createdDate.toISOString().split('T')[0] === today.toISOString().split('T')[0] && 
				payments[x.customerPaymentId] !== x.customerPayment?.paymentAmount);
		});
	}

	ngOnDestroy(): void {
		this.orderLoaded$.unsubscribe();
	}

	async getPDFReceipt(orderId: number) {
		try {
			this.loading = true;
			await this.salesApiService.getPdf(orderId);
		}
		catch (error: any) {
			this.toastService.showError('There was a problem getting the receipt.');
			if (ApiException.isApiException(error)) {
				console.log(error.response);
			}
			else {
				console.log(error);
			}
		}
		finally {
			this.loading = false;
		}
	}

	orderHasPO() {
		return this.orderLayoutService.selectedOrderItems?.findIndex(x => x.poNumber.length > 0) !== -1;
	}
	deleteReasonChange(event: Event) {
		const selectedOption = (event.target as HTMLSelectElement).selectedOptions[0];
    	this.deleteReason = selectedOption ? selectedOption.text : '';
	}
	async deleteOrder() {
		this.submitted = true;
		if (this.deleteReason.length === 0 ||  (this.deleteReason === 'Other' && this.otherReason.length === 0) || (this.orderLayoutService.totalPayment.value > 0 && this.paymentAction === undefined))
		{
			return;
		}
		this.modalService.dismissAll();
		this.loading = true;
		try {
			const reason = this.deleteReason !== 'Other' ? this.deleteReason : `${this.deleteReason}:${this.otherReason}`;
			await this.orderLayoutService.deleteOrder(new DeleteOrderQuery({deleteReason: reason, paymentAction: this.paymentAction}));
			this.orderLayoutService.selectedOrder!.deleted = true;
			this.toastService.showSuccess('The order was deleted successfully');
		}
		catch (error: any) {
			if (error.isApiException && error.status === 400) {
				this.toastService.showError(error.response);
			}
			else {
				this.toastService.showError('There was a problem deleting the order.');
			}
			console.log(error);
		}
		finally {
			this.orderLayoutService.loadOrder(this.orderLayoutService.orderId);
			this.loading = false;
		}
	}

	async restoreOrder() {
		this.loading = true;
		try {
			await this.orderLayoutService.restoreOrder();
			this.toastService.showSuccess('The order was restored successfully');
		}
		catch (error) {
			this.toastService.showError('There was a problem restoring the order.');
		}
		finally {
			this.loading = false;
			this.modalService.dismissAll();
		}
	}

	async reGenerateList() {
		try {
			this.loading = true;
			await this.orderLayoutService.reGenerateList();
		}
		catch {
			this.toastService.showError('There was an error re-generating the list');
		}
		finally {
			this.loading = false;
		}
	}

	updateVentureOneNumber() {
		this.ventureOneNumberInvalid = false;
		if (this.orderLayoutService.ventureOneNumber === undefined || this.orderLayoutService.ventureOneNumber === '') {
			return;
		}
		if (this.orderLayoutService.ventureOneNumber.length < 7 || this.orderLayoutService.ventureOneNumber.length > 20) {
			this.ventureOneNumberInvalid = true;
			return;
		}
		this.loading = true;
		let customerPropertyList: CustomerProperty[] = [];
		const ventureOneProperty = {
			customerPropertyID: 0,
			customerID: this.orderLayoutService.selectedCustomer?.customerID,
			propertyName: 'VentureOne Number',
			propertyValue: this.orderLayoutService.ventureOneNumber
		} as CustomerProperty;
		customerPropertyList.push(ventureOneProperty);
		let success = true;
		try {
			if (this.orderLayoutService.hasVentureOneNumber) {
				this.customerService.updateCustomerProperties(customerPropertyList);
			}
			else {
				this.customerService.createCustomerProperties(customerPropertyList);
			}
		}
		catch (err: any) {
			success = false;
			console.log(err);
			this.toastService.showError('VentureOne Number could not be updated');
		}
		finally {
			this.loading = false;
		}
		if (success) {
			this.toastService.showSuccess('VentureOne Number updated');
		}
	}
}
