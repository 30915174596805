//import { MenuItem } from './menu-item-int';

export enum NavSource {
	Siblings = 'siblings',
	Children = 'children'
}

export interface MenuItem {
	title: string;
	url: string;
	routeUrl: string;
	icon?: string;
	caret?: string;
	submenu?: MenuItem[];
	hide?: boolean;
	hidesubnav?: boolean;
	state?: string;
	expand?: string;
	navsrc?: NavSource;
	class?: string;
	roles?: string[];
	organizations?: number[];
	organizationsToExclude?: number[];
}

const pageMenus: MenuItem[] = [
	{
		icon: 'fa fa-home',
		title: 'Home',
		url: '/',
		routeUrl: '/'
	},
	{
		icon: 'fa fa-ticket',
		title: 'Coupons',
		url: '/sites/-1/coupons',
		routeUrl: '/sites/-1/coupons',
		roles: ['SalesTeam']
	},
	{
		icon: 'fa fa-gift',
		title: 'Bundles',
		url: '/sites/-1/bundles',
		routeUrl: '/sites/-1/bundles',
		organizations: [1],
		roles: ['SalesTeam']
	},
	{
		icon: 'fa fa-address-book',
		title: 'Customers',
		url: '/customers',
		routeUrl: '/customers',
		roles: ['SalesTeam'],
		submenu: [
			{
				title: 'Add Customer',
				url: '^/customers/create',
				routeUrl: '/customers/create',
				hide: true,
				navsrc: NavSource.Children
			},
			{
				icon: 'fa fa-user',
				title: 'Manage Customer',
				url: '^/customers/[0-9]+$',
				routeUrl: '/customers/:customerId',
				hide: true,
				navsrc: NavSource.Children,
				submenu: [
					{
						icon: 'fa fa-map',
						title: 'Maps & Distributions',
						url: '^/customers/[0-9]+/distributions$',
						routeUrl: '/customers/:customerId/distributions',
						hide: true,
						navsrc: NavSource.Siblings,
						submenu: [
							{
								icon: 'fa fa-map',
								title: 'Customer Distribution Details',
								url: '^/customers/[0-9]+/distributions/[0-9]+$',
								routeUrl: '/customers/:customerId/distributions/:distributionId',
								hide: true
							},
							{
								icon: 'fa fa-map-marker-plus',
								title: 'New Map',
								url: '^/customers/[0-9]+/map$',
								routeUrl: '/customers/:customerId/map',
								hide: true
							},
							{
								icon: 'fa fa-map-marker-edit',
								title: 'Update Map',
								url: '^/customers/[0-9]+/map/[0-9]+$',
								routeUrl: '/customers/:customerId/map/:distributionId',
								hide: true
							},
							{
								icon: 'fa fa-clipboard',
								title: 'Paste Routes',
								url: '^/customers/[0-9]+/paste-list$',
								routeUrl: '/customers/:customerId/paste-list',
								hide: true
							},
							{
								icon: 'fa fa-map-marker-plus',
								title: 'New Map',
								url: '^/customers/[0-9]+/snapmap$',
								routeUrl: '/customers/:customerId/snapmap',
								hide: true
							},
							{
								icon: 'fa fa-map-marker-edit',
								title: 'Update Map',
								url: '^/customers/[0-9]+/snapmap/[0-9]+$',
								routeUrl: '/customers/:customerId/snapmap/:distributionId',
								hide: true
							},
							{
								icon: 'fa fa-clipboard',
								title: 'Paste Routes',
								url: '^/customers/[0-9]+/paste-list$',
								routeUrl: '/customers/:customerId/paste-list',
								hide: true
							},
							{
								icon: 'fa fa-file-excel',
								title: 'Upload File',
								url: '^/customers/[0-9]+/upload-list$',
								routeUrl: '/customers/:customerId/upload-list',
								hide: true
							},
							{
								icon: 'fa fa-building',
								title: 'Upload Your List',
								url: '^/customers/[0-9]+/personalized-mail/upload$',
								routeUrl: '/customers/:customerId/personalized-mail/upload',
								hide: true
							},
							{
								icon: 'fa fa-building',
								title: 'Customer List',
								url: '^/customers/[0-9]+/personalized-mail$',
								routeUrl: '/customers/:customerId/personalized-mail',
								hide: true
							},
							{
								icon: 'fa fa-building',
								title: 'Customer List',
								url: '^/customers/[0-9]+/personalized-mail/[0-9]+$',
								routeUrl: '/customers/:customerId/personalized-mail/:distributionId',
								hide: true
							},
							{
								icon: 'fa fa-building',
								title: 'Business List',
								url: '^/customers/[0-9]+/business$',
								routeUrl: '/customers/:customerId/business',
								hide: true
							},
							{
								icon: 'fa fa-building',
								title: 'Update Business List',
								url: '^/customers/[0-9]+/business/[0-9]+$',
								routeUrl: '/customers/:customerId/business/:distributionId',
								hide: true
							},
							{
								icon: 'fa fa-users',
								title: 'Consumer List',
								url: '^/customers/[0-9]+/personalized-mail$',
								routeUrl: '/customers/:customerId/personalized-mail',
								hide: true
							},
							{
								icon: 'fa fa-users',
								title: 'Update Consumer List',
								url: '^/customers/[0-9]+/personalized-mail/[0-9]+$',
								routeUrl: '/customers/:customerId/personalized-mail/:distributionId',
								hide: true
							}
						]
					},
					{
						icon: 'fas fa-crop',
						title: 'Designs',
						url: '^/customers/[0-9]+/designs',
						routeUrl: '/customers/:customerId/designs',
						hide: true,
						navsrc: NavSource.Siblings,
						submenu: [
							{
								icon: 'fas fa-crop',
								title: 'Selected Design',
								url: '^/customers/[0-9]+/designs/[A-Z0-9a-z]+',
								routeUrl: '/customers/:customerId/designs/:projectId',
								hide: true
							}
						]
					},
					{
						icon: 'fa fa-shopping-basket',
						title: 'Carts',
						url: '^/customers/[0-9]+/shop/carts$',
						routeUrl: '/customers/:customerId/shop/carts',
						hide: true,
						navsrc: NavSource.Siblings,
						submenu: [
							{
								icon: 'fa fa-shopping-basket',
								title: 'Build Cart from Distribution',
								url: '^/customers/[0-9]+/shop/[0-9]+/[0-9]+$',
								routeUrl: '/customers/:customerId/shop/:siteId/:distributionId',
								hide: true
							},
							{
								icon: 'fa fa-shopping-basket',
								title: 'Create Print and Ship Cart',
								url: '^/customers/[0-9]+/shop/[0-9]+$',
								routeUrl: '/customers/:customerId/shop/:siteId',
								hide: true
							},
						]
					},
					{
						icon: 'fa fa-shopping-cart',
						title: 'Orders',
						url: '^/customers/[0-9]+/orders$',
						routeUrl: '/customers/:customerId/orders',
						hide: true,
						navsrc: NavSource.Siblings
					},
					{
						icon: 'fa fa-cash-register',
						title: 'Payments',
						url: '^/customers/[0-9]+/payments$',
						routeUrl: '/customers/:customerId/payments',
						hide: true,
						navsrc: NavSource.Siblings,
						submenu: [
							{
								icon: 'fa fa-cash-register',
								title: 'Payment Details',
								url: '',
								routeUrl: '/customers/:customerId/payments/:customerPaymentId',
								hide: true
							},
							{
								icon: 'fa fa-cash-register',
								title: 'Create Payment',
								url: '',
								routeUrl: '/customers/:customerId/payments/create',
								hide: true,
								hidesubnav: true
							},
							{
								icon: 'fa fa-cash-register',
								title: 'Payment Attempt Details',
								url: '',
								routeUrl: '/customers/:customerId/payment-attempts/:customerPaymentAttemptId',
								hide: true
							},
							{
								icon: 'fa fa-cash-register',
								title: 'Refund Payment',
								url: '',
								routeUrl: '/customers/:customerId/payments/:customerPaymentId/refund-payment',
								hide: true
							}
						]
					},
					{
						icon: 'fa fa-user',
						title: 'Create Payment Profile',
						url: '^/customers/[0-9]+/payment-profiles/create-payment-profile$',
						routeUrl: '/customers/:customerId/payment-profiles/create-payment-profile',
						hide: true,
						navsrc: NavSource.Siblings,
                        organizations: [1]
					},
					{
						icon: 'fas fa-truck',
						title: 'Delivery',
						url: '^/customers/[0-9]+/delivery$',
						routeUrl: '/customers/:customerId/delivery',
						hide: true,
						navsrc: NavSource.Siblings
					},
					{
						icon: 'fa fa-address-book',
						title: 'Addresses',
						url: '^/customers/[0-9]+/addresses$',
						routeUrl: '/customers/:customerId/addresses',
						hide: true,
						navsrc: NavSource.Siblings,
						submenu: [
							{
								icon: 'fa fa-address-card',
								title: 'Add New Address',
								url: '',
								routeUrl: '/customers/:customerId/addresses/new-address',
								hide: true
							}
						]
					},
					{
						icon: 'fa fa-shopping-basket',
						title: 'View All Shopping Quotes',
						url: '^/customers/[0-9]+/shop/quotes$',
						routeUrl: '/customers/:customerId/shop/quotes',
						hide: true,
						navsrc: NavSource.Siblings
					}
				]
			}
		]
	},
	{
		icon: 'fa fa-shopping-cart',
		title: 'Orders',
		url: '/orders',
		routeUrl: '/orders',
		roles: ['SalesTeam'],
		submenu: [
			{
				title: 'Order Summary',
				url: '^/orders/[0-9]+$',
				routeUrl: '/orders/:orderId/summary',
				hide: true,
				navsrc: NavSource.Children,
				submenu: [
					{
						title: 'Payments',
						url: '^/orders/[0-9]+/payments',
						routeUrl: '/orders/:orderId/payments',
						hide: true,
						navsrc: NavSource.Siblings
					},
					{
						title: 'Attribution Dashboard',
						url: '^/orders/[0-9]+/attribution-dashboard',
						routeUrl: '/orders/:orderId/attribution-dashboard',
						hide: true,
						navsrc: NavSource.Siblings
					},
					{
						title: 'Order History',
						url: '^/orders/[0-9]+/order-history',
						routeUrl: '/orders/:orderId/order-history',
						hide: true,
						organizations: [1],
						navsrc: NavSource.Siblings
					},
					{
						title: 'Change Order',
						url: '^/orders/[0-9]+/change-order',
						routeUrl: '/orders/:orderId/change-order',
						hide: true,
						organizations: [1],
						navsrc: NavSource.Siblings
					},
					{
						title: 'Order Item Details',
						url: '',
						routeUrl: '/orders/:orderId/details/:orderItemId',
						hide: true,
						hidesubnav: true,
						navsrc: NavSource.Siblings
					}
				]
			}
		]
	},
	{
		icon: 'fa fa-cash-register',
		title: 'Payments',
		url: '/payments',
		routeUrl: '/payments',
		caret: 'true',
		roles: ['SalesTeam']
	},
	{
		icon: 'fa fa-sitemap',
		title: 'Site Products',
		url: '/siteproducts',
		routeUrl: '/siteproducts',
		caret: 'true',
		hidesubnav: true,
		roles: ['SalesTeam'],
		organizationsToExclude: [1],
		submenu: [
			{
				title: 'WL Product',
				url: '^/siteproducts/[0-9]+',
				routeUrl: '/siteproducts/:baseProductId',
				hide: true,
				hidesubnav: true,
				navsrc: NavSource.Siblings
			}
		]
	},
	{
		icon: 'fa fa-sitemap',
		title: 'Site Management',
		url: '',
		routeUrl: '',
		roles: ['SitesAdmin'],
		organizations: [1],
		submenu: [
			{
				title: 'Sites',
				url: '/sites',
				routeUrl: '/sites',
                organizations: [1],
				submenu: [
					{
						title: 'Dashboard',
						url: '^/sites/[0-9]+/dashboard',
						routeUrl: '/sites/:siteId/dashboard',
						hide: true,
						navsrc: NavSource.Children,
                        organizations: [1],
						submenu: [
							{
								title: 'Settings',
								url: '^/sites/[0-9]+/settings',
								routeUrl: '/sites/:siteId/settings',
								hide: true,
								navsrc: NavSource.Siblings,
                                organizations: [1],
							},
							{
								title: 'Details',
								url: '^/sites/[0-9]+/details',
								routeUrl: '/sites/:siteId/details',
								hide: true,
								navsrc: NavSource.Siblings,
                                organizations: [1],
							},
							{
								title: 'Urls',
								url: '^/sites/[0-9]+/urls',
								routeUrl: '/sites/:siteId/urls',
								hide: true,
								navsrc: NavSource.Siblings,
                                organizations: [1],
							},
							{
								title: 'Content',
								url: '^/sites/[0-9]+/content',
								routeUrl: '/sites/:siteId/content',
								hide: true,
								navsrc: NavSource.Siblings,
                                organizations: [1],
							},
							{
								title: 'Products',
								url: '^/sites/[0-9]+/products$',
								routeUrl: '/sites/:siteId/products',
								hide: true,
								navsrc: NavSource.Siblings,
                                organizations: [1],
								submenu: [
									{
										title: 'WL Product Pricing',
										url: '^/sites/[0-9]+/products/[0-9]+$',
										routeUrl: '/sites/:siteId/products/:baseProductId',
										hide: true,
										navsrc: NavSource.Siblings,
									},
									{
										title: 'Add WL Product',
										url: '^/sites/[0-9]+/products/add$',
										routeUrl: '/sites/:siteId/products/add',
										hide: true,
										hidesubnav: true,
										navsrc: NavSource.Siblings,
									}
								]
							},
							{
								title: 'Coupons',
								url: '^/sites/[0-9]+/coupons$',
								routeUrl: '/sites/:siteId/coupons',
								hide: true,
								navsrc: NavSource.Siblings,
                                organizations: [1],
								submenu: [
									{
										title: 'Manage Coupon',
										url: '^/sites/[0-9]+/coupons/[0-9]+$',
										routeUrl: '/sites/:siteId/coupons/:couponId',
										hide: true,
										navsrc: NavSource.Siblings,
									}
								]
							},
							{
								icon: 'fa fa-gift',
								title: 'Bundles',
								url: '^/sites/[0-9]+/bundles$',
								routeUrl: '/sites/:siteId/bundles',
								hide: true,
								navsrc: NavSource.Siblings,
                                organizations: [1],
								submenu: [
									{
										title: 'Add Bundle',
										url: '^/sites/[0-9]+/bundles/add-bundle$',
										routeUrl: '/sites/:siteId/bundles/add-bundle',
										hide: true,
										navsrc: NavSource.Siblings,
									},
									{
										title: 'Update Bundle',
										url: '^/sites/[0-9]+/bundles/[0-9]/update-bundle$',
										routeUrl: '/sites/:siteId/bundles/:bundleId/details',
										hide: true,
										navsrc: NavSource.Siblings,
									}
								]
							},
							{
								title: 'Update Postage',
								url: '^/sites/[0-9]+/update-postage',
								routeUrl: '/sites/:siteId/update-postage',
								hide: false,
								navsrc: NavSource.Siblings,
                                organizations: [1],
							},
							{
								title: 'USelect Add Ons',
								url: '^/sites/[0-9]+/uselect-addons',
								routeUrl: '/sites/:siteId/uselect-addons',
								hide: true,
								navsrc: NavSource.Siblings,
                                organizations: [1],
								submenu: [
									{
										title: 'Site USelect Details',
										url: '^/sites/[0-9]+/uselect-addons/[0-9]+$',
										routeUrl: '/sites/:siteId/uselect-addons/:uSelectId',
										hide: true,
										navsrc: NavSource.Siblings,
									},
									{
										title: 'Add New USelect',
										url: '^/sites/[0-9]+/uselect-addons/add$',
										routeUrl: '/sites/:siteId/uselect-addons/add',
										hide: true,
										navsrc: NavSource.Siblings,
									}
								]
							},
							{
								title: 'USelect Designs',
								url: '^/sites/[0-9]+/uselect-designs',
								routeUrl: '/sites/:siteId/uselect-designs',
								hide: true,
								navsrc: NavSource.Siblings,
                                organizations: [1],
								submenu: [
									{
										title: 'Site USelect Designs',
										url: '^/sites/[0-9]+/uselect-designs/[0-9]+$',
										routeUrl: '/sites/:siteId/uselect-designs/:uSelectId',
										hide: true,
										navsrc: NavSource.Siblings
									},
									{
										title: 'Add New USelect Design',
										url: '^/sites/[0-9]+/uselect-designs/add$',
										routeUrl: '/sites/:siteId/uselect-designs/add',
										hide: true,
										navsrc: NavSource.Siblings
									}
								]
							},
							{
								title: 'Power Ups',
								url: '^/sites/[0-9]+/uselect-power-ups',
								routeUrl: '/sites/:siteId/uselect-power-ups',
								hide: true,
								navsrc: NavSource.Siblings,
                                organizations: [1],
							}

						]
					}
				]
			},
			{
				title: 'Setting Configuration',
				url: '/sites/config',
				routeUrl: '/sites/config',
				submenu: [
					{
						url: '^/sites/config/[A-Z0-9a-z]+/[A-Z0-9a-z]+$',
						routeUrl: '/sites/config/:configCat/:configName',
						title: 'Site Setting Usage',
						hide: true
					}
				]
			},
			{
				title: 'Content Keys',
				url: '/sites/content-keys',
				routeUrl: '/sites/content-keys',
				submenu: [
					{
						url: '^/sites/content-keys/[A-Z0-9a-z]+/[A-Z0-9a-z]+$',
						routeUrl: '/sites/content-keys/:contentKey',
						title: 'Content Key Usage',
						hide: true
					}
				]
			},
			{
				title: 'Organizations',
				url: '/organizations',
				routeUrl: '/organizations'
			}
		]
	},
	{
		icon: 'fa fa-inventory',
		title: 'Products Management',
		url: '',
		routeUrl: '',
		roles: ['SystemAdmin'],
        organizations: [1],
		submenu: [
			{
				url: '/products',
				routeUrl: '/products',
				title: 'All Products',
				hide: false,
				submenu: [
					{
						url: '/products/add',
						routeUrl: '/products/add',
						title: 'Add Product',
						hide: true,
						navsrc: NavSource.Children
					},
					{
						title: 'Manage Product',
						url: '^/products/[0-9]+$',
						routeUrl: '/products/:productId',
						hide: true,
						navsrc: NavSource.Children,
						submenu: [
							{
								title: 'Options',
								url: '^/products/[0-9]+/options$',
								routeUrl: '/products/:productId/options',
								hide: true,
								navsrc: NavSource.Siblings
							},
							{
								title: 'Pricing Grid',
								url: '^/products/[0-9]+/pricing$',
								routeUrl: '/products/:productId/pricing',
								hide: true,
								navsrc: NavSource.Siblings
							},
							{
								title: 'Printer Cost Of Goods',
								url: '^/products/[0-9]+/printer-cost-of-goods$',
								routeUrl: '/products/:productId/printer-cost-of-goods',
								hide: true,
								navsrc: NavSource.Siblings
							},
							{
								title: 'Audit Data',
								url: '^/products/[0-9]+/audit-data$',
								routeUrl: '/products/:productId/audit-data',
								hide: true,
								navsrc: NavSource.Siblings
							}
						]
					}
				]
			},
			{
				icon: 'fa fa-inventory',
				title: 'All Uselects',
				url: '/products/uselects',
				routeUrl: '/products/uselects',
				navsrc: NavSource.Children,
				submenu: [
					{
						url: '/products/uselects/add',
						routeUrl: '/products/uselects/add',
						title: 'Add Uselect',
						hide: true,
						hidesubnav: true
					},
					{
						title: 'Manage Uselect',
						url: '^/products/uselects/[0-9]+$',
						routeUrl: '/products/uselects/:uselectId',
						hide: true,
						navsrc: NavSource.Siblings
					},
					{
						title: 'Update Postage',
						url: '^/products/uselects/[0-9]+$/update-postage',
						routeUrl: '/products/uselects/:uselectId/update-postage',
						hide: true,
					},
					{
						url: '/products/uselects/[0-9]+/powerups',
						routeUrl: '/products/uselects/:uselectId/powerups',
						title: 'Product Powerups',
						hide: true,
						navsrc: NavSource.Siblings
					},
					{
						url: '/products/uselects/[0-9]+/design-turnaround',
						routeUrl: '/products/uselects/:uselectId/design-turnaround',
						title: 'Design Turnaround',
						hide: true,
						navsrc: NavSource.Siblings
					}
				]
			},
			{
				title: 'Induction Turnaround',
				url: '/products/uselects/induction-turnaround',
				routeUrl: '/products/uselects/induction-turnaround'
			},
			{
				title: 'Product Option Categories',
				url: '/option-categories',
				routeUrl: '/option-categories',
				hide: false,
				hidesubnav: true,
				submenu: [
					{
						title: 'Add Category',
						url: '/option-categories/add',
						routeUrl: '/option-categories/add',
						hide: true,
						hidesubnav: true
					},
					{
						title: 'Category Details',
						url: '^/option-categories/[0-9]+$',
						routeUrl: '/option-categories/:categoryId',
						hide: true,
						navsrc: NavSource.Children,
						submenu: [
							{
								title: 'Category Options',
								url: '^/option-categories/[0-9]+/options$',
								routeUrl: '/option-categories/:categoryId/options',
								hide: true,
								navsrc: NavSource.Siblings,
								submenu: [
									{
										title: 'Category Option Details',
										url: '^/option-categories/[0-9]+/options/[0-9]+$',
										routeUrl: '/option-categories/:categoryId/options/:optionId',
										hide: true,
										navsrc: NavSource.Siblings
									}
								]
							}
						]
					}
				]
			},
			{
				url: '/printandship/categories',
				routeUrl: '/printandship/categories',
				title: 'Print And Ship Categories',
				hide: false,
				submenu: [
					{
						title: 'Category Details',
						url: '^/printandship/categories/[0-9]+$',
						routeUrl: '/printandship/categories/:categoryId',
						hide: true
					},
					{
						title: 'Add New Category',
						url: '^/printandship/categories/add',
						routeUrl: '/printandship/categories/add',
						hide: true
					}
				]
			}
		]
	},
	{
		icon: 'fa fa-chart-line',
		title: 'Reports',
		url: '',
		routeUrl: '',
		caret: 'true',
		roles: ['SalesAdmin'],
        organizations: [1],
		submenu: [
			{
				url: '/reports/mcnally',
				routeUrl: '/reports/mcnally',
				title: 'McNally Report'
			},
			{
				url: '/reports/eddm-status',
				routeUrl: '/reports/eddm-status',
				title: 'EDDM Status Report'
			},
			{
				url: '/reports/sales-indicators',
				routeUrl: '/reports/sales-indicators',
				title: 'Sales Indicators Report'
			},
			{
				url: '/reports/attribution',
				routeUrl: '/reports/attribution',
				title: 'Taralytics'
			},
			{
				url: '/reports/order-balance',
				routeUrl: '/reports/order-balance',
				title: 'Order Balance Report'
			},
		]
	},
	{
		icon: 'fa fa-eye',
		title: 'Admin',
		url: '',
		routeUrl: '',
		caret: 'true',
		roles: ['SystemAdmin'],
        organizations: [1],
		submenu: [
			{
				icon: 'fa fa-users',
				url: '/users',
				routeUrl: '/users',
				title: 'Users',
				submenu: [
					{
						icon: 'fa fa-user',
						url: '^/users/[0-9]+$',
						routeUrl: '/users/:customerId',
						title: 'Manage User',
						hide: true
					}
				]
			},
			{
				icon: 'fa fa-user-tag',
				url: '/roles',
				routeUrl: '/roles',
				title: 'Roles'
			},
			{
				icon: 'fa fa-user-secret',
				url: '/debug-user-login',
				routeUrl: '/debug-user-login',
				title: 'Debug User Login'
			},
			{
				icon: 'fa fa-file-search',
				url: '/logs',
				routeUrl: '/logs',
				title: 'Logs'
			},
			{
				icon: 'fa fa-dollar-sign',
				url: '/pmod-config',
				routeUrl: '/pmod-config',
				title: 'PMOD'
			},

			{
				icon: 'fa fa-print',
				url: '/printers',
				routeUrl: '/printers',
				title: 'Printers',
				submenu: [
					{
						icon: 'fa fa-print',
						url: '^/printers/[0-9]+$',
						routeUrl: '/printers/:printerId',
						navsrc: NavSource.Children,
						title: 'Manage Printer',
						hide: true,
						submenu: [
							{
								title: 'Manage Printer Zones',
								url: '^/printers/[0-9]+/printer-zones$',
								routeUrl: '/printers/:printerId/printer-zones',
								hide: true,
								navsrc: NavSource.Siblings
							}
						]
					}
				]
			},
			{
				icon: 'fa fa-upload',
				url: '/printer-cost-of-goods',
				routeUrl: '/printer-cost-of-goods',
				title: 'PCOGs Management'
			}
		]
	}
];

export default pageMenus;
