import { Injectable } from '@angular/core';
import { HttpWithAuth } from 'app/http-with-auth';
import { environment } from 'environment';
import { AddItemToCartQuery, FileResponse, ShoppingCart, ShoppingClient, BuildMyCartQuery } from '@taradel/admin-api-client';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {

	private readonly shoppingClient: ShoppingClient;
	constructor(http: HttpWithAuth) {
		this.shoppingClient = new ShoppingClient(environment.adminApiUrl, http);
	}

	async getShoppingCart(customerId: number, siteId: number): Promise<ShoppingCart> {
		return await this.shoppingClient.getShoppingCart(customerId, siteId);
	}

	async addShoppingCart(customerId: number, siteId: number, query: AddItemToCartQuery): Promise<void> {
		await this.shoppingClient.addItemToShoppingCart(customerId, siteId, query);

	}

	async removeShoppingCart(customerId: number, siteId: number, itemId: string): Promise<FileResponse | null> {
		return await this.shoppingClient.removeItemFromShoppingCart(customerId, siteId, itemId);
	}

	async emptyShoppingCart(customerId: number, siteId: number): Promise<FileResponse | null> {
		return await this.shoppingClient.emptyShoppingCart(customerId, siteId);
	}

	emptyDirectMailCart(siteId: number, customerId: number): Promise<FileResponse | null> {
		return this.shoppingClient.removeItemsWithDistributionFromCustomerCart(siteId, customerId);
	}

	buildCart(query: BuildMyCartQuery): Promise<FileResponse | null> {
		return this.shoppingClient.buildCustomerShoppingCart(query);
	}

	buildCustomerShoppingCart(query: BuildMyCartQuery) {
		return this.shoppingClient.buildCustomerShoppingCart(query);
	}

	async removeBundleFromCustomerCart(customerId: number, siteId: number, bundleId: number) {
		return await this.shoppingClient.removeBundleFromCustomerCart(customerId, siteId, bundleId);
	}
}
