import { HttpWithAuth } from 'app/http-with-auth';
import { Injectable } from '@angular/core';
import { DesignHuddleClient as WebDesignHuddleClient, DownloadAssetResponse, DownloadProjectAssetsRequest, FileResponse, ListProjectsResponse } from '@taradel/web-api-client';
import { DesignHuddleClient, GetCustomerProjectRequest, ReExportProjectRequest, UpdateOrderProjectIdRequest } from '@taradel/admin-api-client';
import { environment } from 'environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root'
})


export class DesignHuddleService {

	private readonly designHuddleClient: DesignHuddleClient;
	private readonly webDesignHuddleClient: WebDesignHuddleClient;

	constructor(http: HttpWithAuth) {
		this.designHuddleClient = new DesignHuddleClient(environment.adminApiUrl, http);
		this.webDesignHuddleClient = new WebDesignHuddleClient(environment.webApiUrl, http);
	}

	getUserToken(siteId: number): Promise<string> {
		return this.webDesignHuddleClient.getUserToken(siteId);
	}

	getCustomerUserToken(customerId: number): Promise<string> {
		return this.designHuddleClient.getCustomerUserToken(customerId);
	}

	getCustomerProjects(request: GetCustomerProjectRequest): Promise<ListProjectsResponse> {
		return this.designHuddleClient.listCustomerProjects(request);
	}

	getCustomerProject(request: GetCustomerProjectRequest): Promise<ListProjectsResponse> {
		return this.designHuddleClient.getCustomerProject(request);
	}

	reExportCustomerProject(request: ReExportProjectRequest) {
		this.designHuddleClient.reExportProject(request);
	}

	updateProjectIdForReExport(request: UpdateOrderProjectIdRequest) {
		this.designHuddleClient.updateProjectIdForReExport(request);
	}

	getAppAccessToken(): Promise<string> {
		return this.designHuddleClient.getAppAccessToken();
	}

	getProjectAssets(request: DownloadProjectAssetsRequest): Promise<DownloadAssetResponse[]> {
		return this.webDesignHuddleClient.downloadProjectAsset(request);
	}

	getProjectAssetsAsZip(request: DownloadProjectAssetsRequest): Promise<FileResponse | null> {
		return this.designHuddleClient.downloadProjectAssetFileZip(request);
	}
}
