import { Injectable } from '@angular/core';
import {
	CustomerClient, Customer, CustomerProperty, FileResponse, FileParameter, CustomerSearch, CustomerSearchMapTransfer,
	CustomerShippingClient, CustomerShippingAddress, CustomerSearchResult,
    UpdateCustomerSite,
	UserEmailAlias,
	AddUserEmailAliasQuery} from '@taradel/admin-api-client';
import { HttpWithAuth } from 'app/http-with-auth';
import { environment } from 'environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CustomerService {

	private readonly customerClient: CustomerClient;
	private readonly customerShippingClient: CustomerShippingClient;

	constructor(http: HttpWithAuth) {
		this.customerClient = new CustomerClient(environment.adminApiUrl, http);
		this.customerShippingClient = new CustomerShippingClient(environment.adminApiUrl, http);
	}

	getCustomer(customerId: number): Promise<Customer> {
		return this.customerClient.getCustomer(customerId);
	}

	async updateCustomer(customer: Customer): Promise<void> {
		await this.customerClient.updateCustomer(customer);
	}

	async updateCustomerSite(request: UpdateCustomerSite): Promise<void> {
		await this.customerClient.updateCustomerSite(request);
	}

	async createCustomerProperties(customerProperties: CustomerProperty[]): Promise<void> {
		await this.customerClient.createCustomerProperties(customerProperties);
	}

	async updateCustomerProperties(customerProperties: CustomerProperty[]): Promise<void> {
		await this.customerClient.updateCustomerProperties(customerProperties);
	}

	async getCustomersCount(customerSearch: CustomerSearch): Promise<number> {
		return await this.customerClient.searchCustomersCount(customerSearch);
	}

	async getAllCustomers(customerSearch: CustomerSearch): Promise<CustomerSearchResult[]> {
		return await this.customerClient.searchCustomers(customerSearch);
	}

	async searchCustomersForMapTransfer(customerSearch: CustomerSearchMapTransfer): Promise<Customer[]> {
		return await this.customerClient.searchCustomersForMapTransfer(customerSearch);
	}

	async getUserEmailAliases(customerId: number): Promise<UserEmailAlias[]> {
		return await this.customerClient.getUserEmailAliases(customerId);
	}

	async addUserEmailAlias(query: AddUserEmailAliasQuery): Promise<number> {
		return await this.customerClient.addUserEmailAlias(query);
	}

	async deleteUserEmailAlias(customerId: number, organizationId: number): Promise<number> {
		return await this.customerClient.deleteUserEmailAlias(customerId, organizationId);
	}

	async getCustomerShippingAddress(shippingAddressId: number): Promise<CustomerShippingAddress> {
		return await this.customerShippingClient.getCustomerShippingAddress(shippingAddressId);
	}

	async getCustomerTaxExemptionDoc(customerId: number): Promise<void> {
		const fileResponse = await this.customerClient.getTaxExemptionCertificate(customerId);
		const downloadURL = window.URL.createObjectURL(fileResponse?.data!);
		const link = document.createElement('a');
		link.href = downloadURL;
		link.download = `${customerId}-ExemptionCertificate.pdf`;
		link.click();
	}

	async uploadCustomerTaxExemptionDoc(customerId: number, certificate: FileParameter): Promise<FileResponse | null> {
		return await this.customerClient.uploadTaxExemptionCertificate(customerId, certificate);
	}
}
