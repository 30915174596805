import { Injectable } from '@angular/core';
import { PrinterClient, Printer, PrintersSearch, PrinterCostOfGoods, ProductPCOGSearch, PCOGKeyQuery, UpdatePCOGRequest, EligiblePrinterSelectionRequest,
	EligiblePrinterSelectionResponse, PrinterZone, RoundRobinPrinterSelection, ProductOption, ProductOptionCategory,
	FileParameter, ExportPCOGRequest } from '@taradel/admin-api-client';
import { HttpWithAuth } from 'app/http-with-auth';
import { environment } from 'environment';
import { lastValueFrom } from 'rxjs';
import { ProductsService } from './products.service';

export interface PCOGSLayoutItem extends PrinterCostOfGoods {
	optionsList?: ProductOption[]
}

export interface PCOGSLayout {
	printerName: string;
	printerId: number;
	pcogs: PCOGSLayoutItem[];
	newPCOG: PCOGSLayoutItem;
}

@Injectable({
	providedIn: 'root'
})

export class PrintersService {

	private readonly printerClient: PrinterClient;

	constructor(http: HttpWithAuth,
		private productsService: ProductsService) {
		this.printerClient = new PrinterClient(environment.adminApiUrl, http);
	}

	getAllPrinters(): Promise<Printer[]> {
		return this.printerClient.getAllPrinters();
	}
	getAffiliatePrinters(): Promise<Printer[]> {
		return this.printerClient.getAffiliatePrinters();
	}

	getAllPrintersWithPagination(query: PrintersSearch): Promise<Printer[]> {
		return this.printerClient.getAllPrintersWithPagination(query);
	}

	getAllPrintersWithPaginationCount(query: PrintersSearch): Promise<number> {
		return this.printerClient.getAllPrintersWithPaginationCount(query);
	}

	getPrinter(id: number): Promise<Printer> {
		return this.printerClient.getPrinter(id);
	}

	addPrinter(printer: Printer): Promise<number> {
		return this.printerClient.addNewPrinter(printer);
	}

	updatePrinter(printer: Printer): Promise<number> {
		return this.printerClient.updatePrinter(printer);
	}

	disableEnablePrinter(printerId: number): Promise<Printer> {
		return this.printerClient.disableEnablePrinter(printerId);
	}

	updatePrinterCostOfGoods(request: UpdatePCOGRequest): Promise<number> {
		return this.printerClient.updatePrinterCostOfGoods(request);
	}

	addPrinterCostOfGoods(reqBody: PrinterCostOfGoods): Promise<number> {
		return this.printerClient.addPrinterCostOfGoods(reqBody);
	}

	removePrinterCostOfGoods(query: PCOGKeyQuery): Promise<number> {
		return this.printerClient.removePrinterCostOfGoods(query);
	}

	getPrinterCostOfGoodsForProduct(search: ProductPCOGSearch): Promise<PrinterCostOfGoods[]> {
		return this.printerClient.getPrinterCostOfGoodsForProduct(search);
	}

	async getPCOGSLayoutForProduct(search: ProductPCOGSearch): Promise<PCOGSLayout[]> {
		const pcogsList = await this.getPrinterCostOfGoodsForProduct(search);
		const productOptionsByCategory = await this.productsService.getProductOptionsByCategory(search.productId);
		const printerIdsList = pcogsList.map(item => item.printerId).filter((value, index, self) => self.indexOf(value) === index);
		const pcogsLayout = [];
		for (let i = 0; i < printerIdsList.length; i++) {
			const printerName = (await this.getPrinter(printerIdsList[i])).companyName;
			const printerPCOGS = pcogsList.filter(x => x.printerId === printerIdsList[i]);
			let newPCOGSList = this.formatPCOGsLayoutItem(printerPCOGS, productOptionsByCategory);
			const newPCOG = {
				productId: search.productId,
				printerId: printerIdsList[i],
				minQty: 0,
				maxQty: 0,
				pricePerPiece: 0,
				prepPerPiece: 0,
				rangeMinCharge: undefined,
				standardManfufacturingTime: undefined,
				fastManufacturingTime: undefined,
				rushManufacturingTime: undefined,
				options: '',
				optionsList: undefined,
				postagePerPiece: undefined
			} as PCOGSLayoutItem;
			const pcogItem = {
				printerName,
				printerId: printerIdsList[i],
				pcogs: newPCOGSList,
				newPCOG
			} as PCOGSLayout;
			pcogsLayout.push(pcogItem);
		}
		await Promise.all(pcogsLayout);
		return pcogsLayout;
	}

	formatPCOGsLayoutItem(pcogsList: PrinterCostOfGoods[], optionsByCategory: ProductOptionCategory[]): PCOGSLayoutItem[] {
		let newPCOGSList: PCOGSLayoutItem[] = [];
		pcogsList.forEach(item => {
			let optionsList: ProductOption[] = [];
			const optsArray = item.options !== '' ? item.options?.split(',') : [];
			if (optsArray!.length > 0) {
				optsArray?.forEach(optId => {
					const option = optionsByCategory.find(cat => cat.options?.some(opt => opt.optionId === parseInt(optId)))?.options?.find(catOpt => catOpt.optionId === parseInt(optId));
					if (!!option) {
						optionsList.push(option);
					}
				});
			}
			const newItem = {
				printerId: item.printerId,
				productId: item.productId,
				minQty: item.minQty,
				maxQty: item.maxQty,
				pricePerPiece: item.pricePerPiece,
				prepPerPiece: item.prepPerPiece,
				rangeMinCharge: item.rangeMinCharge,
				standardManfufacturingTime: item.standardManfufacturingTime,
				fastManufacturingTime: item.fastManufacturingTime,
				rushManufacturingTime: item.rushManufacturingTime,
				percentOfBudget: item.percentOfBudget,
				options: item.options,
				postagePerPiece: item.postagePerPiece,
				optionsList
			} as PCOGSLayoutItem;
			newPCOGSList.push(newItem);
		});
		return newPCOGSList;
	}

	getPrintersToAddToProduct(search: ProductPCOGSearch): Promise<Printer[]> {
		return this.printerClient.getPrintersToAddToProduct(search);
	}

	removePrinterPCOGsFromProduct(productId: number, printerId: number): Promise<number> {
		return this.printerClient.removePrinterPCOGsFromProduct(productId, printerId);
	}

	copyPCOGsToPrinter(query: PCOGKeyQuery[], printerId: number): Promise<number> {
		return this.printerClient.copyPCOGsToPrinter(query, printerId);
	}

	async exportPCOGs(request: ExportPCOGRequest): Promise<void> {
		const fileResponse = await this.printerClient.exportPCOGs(request);
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(fileResponse?.data!);
		let date = new Date;
		const filename = `PrinterCostOfGoods-${date.toLocaleDateString()}`;
		link.download = filename + '.csv';
		link.click();
	}

	async importPCOGs(file: FileParameter): Promise<number> {
		const result = await this.printerClient.importPCOGs(file);
		return result;
	}

	getEligiblePrinters(query: EligiblePrinterSelectionRequest): Promise<EligiblePrinterSelectionResponse> {
		return this.printerClient.eligiblePrinters(query);
	}
	getAllZonesByPrinter(printerId: number, minZip3: number, zone: number): Promise<PrinterZone[]> {
		return this.printerClient.getZonesByPrinter(printerId, minZip3, zone);
	}

	updatePrinterZone(zone: PrinterZone, printerId: string): Promise<PrinterZone> {
		return this.printerClient.updatePrinterZone(zone, printerId);
	}

	createPrinterZone(zone: PrinterZone, printerId: string): Promise<PrinterZone> {
		return this.printerClient.createPrinterZone(zone, printerId);
	}

	removePrinterZone(printerId: number, minZip3: number, maxZip3: number, zone: number): Promise<number> {
		return this.printerClient.removePrinterZone(printerId, minZip3, maxZip3, zone);
	}

	getPrinterSelection(rrSelectionId: number): Promise<RoundRobinPrinterSelection> {
		return this.printerClient.getPrinterSelection(rrSelectionId);
	}
}
