<app-customer-header [customerId]="customerId">
	<ng-container altnav>
		<ul class="nav nav-pills justify-content-end">
			<li class="nav-item">
				<a class="nav-link" [routerLink]="['/customers', customerId, 'distributions']">Back to Distributions</a>
			</li>
			<li class="nav-item">
				<a class="nav-link active" [routerLink]="['/customers', customerId, 'personalized-mail']">Build Consumer List</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" [routerLink]="['/customers', customerId, 'personalized-mail', 'upload']">Upload List</a>
			</li>
		</ul>
	</ng-container>
</app-customer-header>
<app-spinner-with-overlay [showSpinner]="showSpinner">
	<div class="row mt-2">
		<div class="col-md-8">
			<div class="card">
				<div class="card-header">
					<ul class="nav nav-tabs card-header-tabs">
						<li class="nav-item">
							<button class="nav-link active" data-bs-toggle="tab"
								data-bs-target="#locationTab">Location</button>
						</li>
						<li class="nav-item">
							<button class="nav-link" [attr.data-bs-toggle]="locationAdded ? 'tab' : ''" data-bs-target="#basicTab">Basic</button>
						</li>
						<li class="nav-item">
							<button class="nav-link" [attr.data-bs-toggle]="locationAdded ? 'tab' : ''"
								data-bs-target="#housingTab">Housing</button>
						</li>
						<li class="nav-item">
							<button class="nav-link" [attr.data-bs-toggle]="locationAdded ? 'tab' : ''" data-bs-target="#financeTab">Finance</button>
						</li>
						<li class="nav-item">
							<button class="nav-link" [attr.data-bs-toggle]="locationAdded ? 'tab' : ''" data-bs-target="#interestsTab">Interests</button>
						</li>
					</ul>
				</div>
				<div class="tab-content">
					<div class="card-body tab-pane active" id="locationTab" role="tabpanel">
						<h5 class="stats-title">Search using an address or list of ZIP codes, but not both</h5>
						<app-zipcodes-address (updateLocation)="locationUpdated($event)"></app-zipcodes-address>
					</div>
					<div class="card-body tab-pane" id="basicTab" role="tabpanel">
						<div class="row px-4">
							<div class="col-sm-6 custom-slider">
								<h4 class="text-black">Age</h4>
								<app-slider
									[selectionService]="pmSelectionService"
									[sliderOptions]='ageOptions'
									queryField='age'
									[sliderMin]='ageMin'
									[sliderMax]='ageMax'>
								</app-slider>
							</div>
						</div>
						<app-select [selectionService]="pmSelectionService"
							title="Children" queryField="children_Present"></app-select>

						<app-select [selectionService]="pmSelectionService"
							title="Gender" queryField="gender"></app-select>

						<app-select [selectionService]="pmSelectionService"
							title="Homeowner" queryField="home_Owner_Renter"></app-select>

						<app-select [selectionService]="pmSelectionService"
							title="Marital Status" queryField="marital_Status"></app-select>

						<app-select [selectionService]="pmSelectionService"
							title="Education" queryField="education_Individual"></app-select>
					</div>
					<div class="card-body tab-pane" id="housingTab" role="tabpanel">
						<div class="row px-4">
							<div class="col-sm-6 custom-slider">
								<h4 class="text-black">Length of Residence</h4>
								<app-slider
									[selectionService]="pmSelectionService"
									[sliderOptions]='lorOptions'
									queryField='length_Of_Residence'
									[sliderMin]='lorMin'
									[sliderMax]='lorMax'>
								</app-slider>
							</div>
						</div>

						<div class="row px-4">
							<div class="col-sm-6 custom-slider">
								<h4 class="text-black">Year Home Built</h4>
								<app-slider
									[selectionService]="pmSelectionService"
									[sliderOptions]='ybhOptions'
									queryField='home_Built_Year'
									[sliderMin]='ybhMin'
									[sliderMax]='ybhMax'>
								</app-slider>
							</div>
						</div>
						<app-select [selectionService]="pmSelectionService"
							title="Dwelling Type" queryField="dwelling_Type"></app-select>
					</div>
					<div class="card-body tab-pane" id="financeTab" role="tabpanel">
						<div class="row px-4">
							<div class="col-sm-6 custom-slider">
								<h4 class="text-black">Income</h4>
								<app-slider #incomeSlider
									[selectionService]="pmSelectionService"
									[sliderOptions]='incomeOptions'
									queryField='income_Code'
									[sliderMin]='incomeMin'
									[sliderMax]='incomeMax'>
								</app-slider>
							</div>
						</div>

						<div class="row px-4">
							<div class="col-sm-6 custom-slider">
								<h4 class="text-black">Net Worth</h4>
								<app-slider
									[selectionService]="pmSelectionService"
									[sliderOptions]='netWorthOptions'
									queryField='networth_Code'
									[sliderMin]='netWorthMin'
									[sliderMax]='netWorthMax'>
								</app-slider>
							</div>
						</div>


						<div class="row px-4">
							<div class="col-sm-6 custom-slider">
								<h4 class="text-black">Estimated Home Value</h4>
								<app-slider
									[selectionService]="pmSelectionService"
									[sliderOptions]='homeValueOptions'
									queryField='home_Value_Code'
									[sliderMin]='homeValueMin'
									[sliderMax]='homeValueMax'>
								</app-slider>
							</div>
						</div>
						<div class="row">
							<div class="col-6">
								<app-toggle [selectionService]="pmSelectionService" name="Credit Card" queryField="credit_Card_User"></app-toggle>
								<app-toggle [selectionService]="pmSelectionService" name="Charity Donor" queryField="donor_Charitable"></app-toggle>
							</div>
						</div>
					</div>
					<div class="card-body tab-pane" id="interestsTab" role="tabpanel">
						<div class="row mb-2">
							<div class="col-6">
								<h5>General</h5>
								<app-toggle [selectionService]="pmSelectionService" name="Book Reader" queryField="reading_General"></app-toggle>
								<app-toggle [selectionService]="pmSelectionService" name="Technology" queryField="high_Tech_General"></app-toggle>
							</div>
						</div>

						<div class="row mb-2">
							<div class="col-6">
								<h5>Home, Garden & Crafts</h5>
								<app-toggle [selectionService]="pmSelectionService" name="Gardening" queryField="gardening"></app-toggle>
								<app-toggle [selectionService]="pmSelectionService" name="Photography" queryField="photography"></app-toggle>
								<app-toggle [selectionService]="pmSelectionService" name="Sewing and Knitting" queryField="sewing_Knitting_Needlework"></app-toggle>
								<app-toggle [selectionService]="pmSelectionService" name="Woodworking" queryField="woodworking"></app-toggle>
							</div>
						</div>

						<div class="row mb-2">
							<div class="col-6">
								<h5>Travel</h5>
								<app-toggle [selectionService]="pmSelectionService" name="Cruise Travel" queryField="travel_Cruises"></app-toggle>
								<app-toggle [selectionService]="pmSelectionService" name="Domestic Travel" queryField="travel_Domestic"></app-toggle>
								<app-toggle [selectionService]="pmSelectionService" name="General Travel" queryField="travel_Grouping"></app-toggle>
								<app-toggle [selectionService]="pmSelectionService" name="International Travel" queryField="travel_International"></app-toggle>
							</div>
						</div>

						<div class="row mb-2">
							<div class="col-6">
								<h5>Fitness and Health</h5>
								<app-toggle [selectionService]="pmSelectionService" name="Running" queryField="exercise_Running_Jogging"></app-toggle>
								<app-toggle [selectionService]="pmSelectionService" name="Walking" queryField="exercise_Walking"></app-toggle>
								<app-toggle [selectionService]="pmSelectionService" name="Aerobics" queryField="exercise_Aerobic"></app-toggle>
							</div>
						</div>

						<div class="row mb-2">
							<div class="col-6">
								<h5>Outdoor</h5>
								<app-toggle [selectionService]="pmSelectionService" name="General Outdoor" queryField="outdoor_Enthusiast_General"></app-toggle>
								<app-toggle [selectionService]="pmSelectionService" name="Winter / Snow Sports" queryField="sports_Skiing"></app-toggle>
								<app-toggle [selectionService]="pmSelectionService" name="Hunting" queryField="outdoor_Hunting_Shooting"></app-toggle>
								<app-toggle [selectionService]="pmSelectionService" name="Fishing" queryField="outdoor_Fishing"></app-toggle>
							</div>
						</div>

						<div class="row mb-2">
							<div class="col-6">
								<h5>Food & Cooking</h5>
								<app-toggle [selectionService]="pmSelectionService" name="Gourmet Food" queryField="cooking_Gourmet"></app-toggle>
								<app-toggle [selectionService]="pmSelectionService" name="Wine" queryField="food_Wines"></app-toggle>
								<app-toggle [selectionService]="pmSelectionService" name="General Cooking" queryField="cooking_General"></app-toggle>
								<app-toggle [selectionService]="pmSelectionService" name="Natural Foods" queryField="foods_Natural"></app-toggle>
							</div>
						</div>

						<div class="row mb-2">
							<div class="col-6">
								<h5>Health, Diet & Self-Improvement</h5>
								<app-toggle [selectionService]="pmSelectionService" name="General Health / Medical" queryField="health_Medical"></app-toggle>
								<app-toggle [selectionService]="pmSelectionService" name="Dieting / Weight Loss" queryField="dieting_Weight_Loss"></app-toggle>
								<app-toggle [selectionService]="pmSelectionService" name="Self Improvement" queryField="self_Improvement"></app-toggle>
							</div>
						</div>

						<div class="row mb-2">
							<div class="col-6">
								<h5>Sports</h5>
								<app-toggle [selectionService]="pmSelectionService" name="General" queryField="spectator_Sports_General"></app-toggle>
								<app-toggle [selectionService]="pmSelectionService" name="Football" queryField="spectator_Sports_Football"></app-toggle>
								<app-toggle [selectionService]="pmSelectionService" name="Baseball" queryField="spectator_Sports_Baseball"></app-toggle>
								<app-toggle [selectionService]="pmSelectionService" name="Golf" queryField="sports_Golf"></app-toggle>
								<app-toggle [selectionService]="pmSelectionService" name="Tennis" queryField="sports_Tennis"></app-toggle>
								<app-toggle [selectionService]="pmSelectionService" name="Auto Racing" queryField="spectator_NASCAR"></app-toggle>
							</div>
						</div>

						<div class="row mb-2">
							<div class="col-6">
								<h5>Home Improvement</h5>
								<app-toggle [selectionService]="pmSelectionService" name="Home Improvement Services" queryField="home_Improvement"></app-toggle>
								<app-toggle [selectionService]="pmSelectionService" name="Do-It-Yourself Home Improvement" queryField="home_Improvement_Diy"></app-toggle>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-4">
			<app-selection-summary (listSaved)="saveList($event)"></app-selection-summary>
		</div>
	</div>
</app-spinner-with-overlay>

