import { HttpWithAuth } from 'app/http-with-auth';
import { Injectable } from '@angular/core';
import { PersonalizedMailClient as WebPersonalizedMailClient, CountsDetail, MatchedAddress, PersonalizedUploadListResponse, PMCertifyUploadedListRequest,
	PMIdentifyListRequest, PMIdentifyListResponse,
	UploadedListCountsDetail } from '@taradel/web-api-client';
import { BuiltPersonalizedMailDistribution, Distribution, FileParameter, PersonalizedMailClient, PersonalizedMailQuery, PMSaveListQuery, PMSaveUploadedListQuery, SuppressListQuery } from '@taradel/admin-api-client';
import { environment } from '../../environments/environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class PmDataService {

	private pmClient: PersonalizedMailClient;
	private webPmClient: WebPersonalizedMailClient;

	constructor(http: HttpWithAuth) {
		this.pmClient = new PersonalizedMailClient(environment.adminApiUrl, http);
		this.webPmClient = new WebPersonalizedMailClient(environment.webApiUrl, http);
	}

	getCount(query: PersonalizedMailQuery): Promise<CountsDetail> {
		return this.webPmClient.countsViaService(query);
	}

	getAddressPreview(query: PersonalizedMailQuery): Promise<MatchedAddress[]> {
		return this.webPmClient.getAddressPreview(query);
	}

	getBuiltList(distributionId: number): Promise<BuiltPersonalizedMailDistribution> {
		return this.pmClient.getBuiltList(distributionId);
	}

	saveBuiltList(customerId: number, query: PMSaveListQuery) {
		return this.pmClient.saveBuiltList(customerId, query);
	}

	storeUploadedList(uploadedFile: FileParameter): Promise<PersonalizedUploadListResponse> {
		return this.webPmClient.storeUploadedList(uploadedFile);
	}

	identifyUploadedListColumns(query: PMIdentifyListRequest): Promise<PMIdentifyListResponse> {
		return this.webPmClient.identifyUploadedListColumns(query);
	}

	certifyUploadedList(query: PMCertifyUploadedListRequest): Promise<UploadedListCountsDetail> {
		return this.webPmClient.certifyUploadedList(query);
	}

	saveUploadedList(customerId: number, query: PMSaveUploadedListQuery): Promise<number> {
		return this.pmClient.saveUploadedList(customerId, query);
	}

	getCustomerDistributionsForSuppression(customerId: number, distributionId: number): Promise<Distribution[]> {
		return this.pmClient.getCustomerListOfDistributionsForSuppression(customerId, distributionId);
	}

	processListSuppression(query: SuppressListQuery): Promise<boolean> {
		return this.pmClient.suppressDistribution(query);
	}
}
