import { Injectable } from '@angular/core';
import { HttpWithAuth } from 'app/http-with-auth';
import { environment } from 'environment';
import { Receipt, ShoppingClient, ShoppingQuote, ReceiptClient, PlaceOrderRequest, PaymentRequest, OrdersClient, OrderInfo, QuoteSearchResponse,
	QuoteSearchQuery, UpdateQuoteExpirationQuery } from '@taradel/admin-api-client';

@Injectable({
	providedIn: 'root'
})
export class ShoppingQuoteService {
	private readonly shoppingClient: ShoppingClient;
	private readonly receiptClient: ReceiptClient;
	private readonly ordersClient: OrdersClient;
	constructor(http: HttpWithAuth) {
		this.shoppingClient = new ShoppingClient(environment.adminApiUrl, http);
		this.receiptClient = new ReceiptClient(environment.adminApiUrl, http);
		this.ordersClient = new OrdersClient(environment.adminApiUrl, http);
	}

	async getQuoteByGuid(customerId: number, quoteId: string): Promise<ShoppingQuote> {
		return await this.shoppingClient.getShoppingQuote(customerId, quoteId);
	}

	async getQuoteLineItems(quoteId: string): Promise<Receipt> {
		return await this.receiptClient.getShoppingQuoteLineItems(quoteId);
	}

	async placeOrder(quoteId: string, paymentRequest: PaymentRequest): Promise<number> {
		return await this.shoppingClient.placeOrder(new PlaceOrderRequest({
			quoteId: quoteId,
			paymentRequest: new PaymentRequest({
				useBalance: paymentRequest.useBalance,
				paymentType: paymentRequest.paymentType,
				customerProfileId: paymentRequest.customerProfileId ?? undefined,
				customerPaymentProfileId: paymentRequest.customerPaymentProfileId ?? undefined,
				creditCardPaymentRequest: paymentRequest.creditCardPaymentRequest,
				achPaymentRequest: paymentRequest.achPaymentRequest,
				withTaradelFinancing: paymentRequest.withTaradelFinancing,
				poNumber: paymentRequest.poNumber ?? undefined,
				creditKeyOrderKey: paymentRequest.creditKeyOrderKey ?? undefined
			}),
		}));
	}

	async getOrderInfo(orderId: number): Promise<OrderInfo> {
		return await this.ordersClient.getOrderInfo(orderId);
	}

	async getOrderShoppingQuote(orderId: number): Promise<ShoppingQuote> {
		return await this.shoppingClient.getOrderShoppingQuote(orderId);
	}

	async searchCustomerQuotes(search: QuoteSearchQuery): Promise<QuoteSearchResponse[]> {
		return await this.shoppingClient.searchCustomerShoppingQuotes(search);
	}

	async getCustomerQuotesCount(search: QuoteSearchQuery): Promise<number> {
		return await this.shoppingClient.getCustomerQuotesSearchCount(search);
	}

	async getQuoteCheckoutLink(quoteId: string): Promise<string> {
		return await this.receiptClient.generateQuoteCheckoutLink(quoteId);
	}

	async updateShoppingQuoteExpiration(quoteId: string,expirationDate: Date): Promise<void> {
		await this.shoppingClient.updateShoppingQuoteExpiration(new UpdateQuoteExpirationQuery({
			expirationDate,
			quoteId
		}));
	}
}
