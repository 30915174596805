import { HttpWithAuth } from 'app/http-with-auth';
import { Injectable } from '@angular/core';
import { EquifaxClient as WebEquifaxClient, EquifaxMatchedAddress } from '@taradel/web-api-client';
import { BuiltEquifaxB2BDistribution, EquifaxClient, EquifaxQuery, SaveListQuery } from '@taradel/admin-api-client';
import { environment } from '../../environments/environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class EquifaxDataService {

	private equifaxClient: EquifaxClient;
	private webEquifaxClient: WebEquifaxClient;

	constructor(http: HttpWithAuth) {
		this.equifaxClient = new EquifaxClient(environment.adminApiUrl, http);
		this.webEquifaxClient = new WebEquifaxClient(environment.webApiUrl, http);
	}

	getCount(query: EquifaxQuery): Promise<number> {
		return this.webEquifaxClient.getCount(query);
	}

	getBusinessList(distributionId: number): Promise<BuiltEquifaxB2BDistribution> {
		return this.equifaxClient.getBuiltList(distributionId);
	}

	saveBusinessList(customerId: number, name: string, query: EquifaxQuery): Promise<number> {
		return this.equifaxClient.saveBusinessList(new SaveListQuery({
			customerId: customerId,
			name,
			query
		}));
	}

	getAddressPreview(query: EquifaxQuery): Promise<EquifaxMatchedAddress[]> {
		return this.webEquifaxClient.getAddressPreview(query);
	}
}
