import { HttpWithAuth } from 'app/http-with-auth';
import { Injectable } from '@angular/core';
import { PageSize, Template, TemplatesClient } from '@taradel/admin-api-client';
import { environment } from 'environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class TemplateService {


	private readonly templatesClient: TemplatesClient;
	constructor(http: HttpWithAuth) {
		this.templatesClient = new TemplatesClient(environment.adminApiUrl, http);
	}

	getPageSizes(): Promise<PageSize[]> {
		return this.templatesClient.getPageSizes();
	}

	getTemplate(artKey: number): Promise<Template> {
		return this.templatesClient.getTemplate(artKey);
	}
}
