import { Injectable } from '@angular/core';
import { ChangeOrdersClient, AddOrderItemQuery, Coupon, RefundOrderItemLinesQuery } from '@taradel/admin-api-client';
import { HttpWithAuth } from 'app/http-with-auth';
import { environment } from 'environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ChangeOrdersService {

	private readonly changeOrdersClient: ChangeOrdersClient;

	constructor(http: HttpWithAuth) {
		this.changeOrdersClient = new ChangeOrdersClient(environment.adminApiUrl, http);
	}

	async refundOrderItemLines(query: RefundOrderItemLinesQuery): Promise<number> {
		return await this.changeOrdersClient.refundOrderItemLines(query);
	}

	async addOrderItem(query: AddOrderItemQuery): Promise<number> {
		return await this.changeOrdersClient.addOrderItem(query);
	}

	async updateOrderItem(query: AddOrderItemQuery): Promise<boolean> {
		return await this.changeOrdersClient.updateOrderItem(query);
	}

	async deleteOrderItem(orderItemId: number): Promise<boolean> {
		return await this.changeOrdersClient.deleteOrderItem(orderItemId);
	}

	async validateOrderCoupon(orderid: number, couponCode: string): Promise<Coupon> {
		return await this.changeOrdersClient.validateOrderCoupon(orderid, couponCode);
	}

	async updateOrderCoupon(orderId: number, couponCode: string): Promise<boolean> {
		return await this.changeOrdersClient.updateOrderCoupon(orderId, couponCode);
	}

	async validateOrderBundle(orderid: number, bundleId: number): Promise<boolean> {
		return await this.changeOrdersClient.validateOrderBundle(orderid, bundleId);
	}

	async updateOrderBundle(orderId: number, bundleId: number): Promise<boolean> {
		return await this.changeOrdersClient.bundleOrderItems(orderId, bundleId);
	}

	async removeCouponFromOrder(orderId: number, couponCode: string): Promise<boolean> {
		return await this.changeOrdersClient.removeCouponFromOrder(orderId, couponCode);
	}
}
