import { Injectable } from '@angular/core';
import {
	OrdersClient, OrderSearch, Order, ExtendedOrderItem, OrderPayment,
	ReportsClient, OrdersQuery, OrderItem, CartProduct, OrderNote, OrderNoteRequest, OrderTag,
	FileResponse, GetCustomerDistributionsQuery, CustomerDistributionWithRelatedOrders,
	GetCustomerLastOrderQuery, UpdateClickThroughURLQuery, FileParameter, OrderTracking, GetOrderBalanceQuery, OrderRemainingBalance,
	MyOrdersQuery, UpdateOrderItemDropDistribution, DropDistribution, GroupedOrderPaymentDisplay, S3ObjectVersion,
	SplitOrderItemIntoDropsQuery, PaymentClient, InvoiceDisplay, CreateInvoiceRequest,
	UpdateOrderItemDrop, ScheduledPaymentDisplay, OrderItemDrop, OrderItemLineDisplay, OrderPaymentDisplay, QRCodeMetaRequest,
    GroupedOrderItemLines,
    OrderItemAudit,
    Audit,
    DeleteOrderQuery} from '@taradel/admin-api-client';
import { HttpWithAuth } from 'app/http-with-auth';
import { environment } from 'environment';
import { lastValueFrom } from 'rxjs';

export const QRCodePhoneNumber = 'QRCodePhoneNumber';
export const QRCodeWebLink = 'QRCodeWebLink';

@Injectable({
	providedIn: 'root'
})

export class OrderService {

	private readonly orderClient: OrdersClient;
	private readonly paymentClient: PaymentClient;
	private readonly reportsClient: ReportsClient;
	constructor(http: HttpWithAuth) {
		this.orderClient = new OrdersClient(environment.adminApiUrl, http);
		this.paymentClient = new PaymentClient(environment.adminApiUrl, http);
		this.reportsClient = new ReportsClient(environment.adminApiUrl, http);
	}

	async getCustomerOrdersCount(orderSearch: OrderSearch): Promise<number> {
		return await this.orderClient.getCustomerOrdersCount(orderSearch);
	}
	async getCustomerOrders(orderSearch: OrderSearch): Promise<Order[]> {
		return await this.orderClient.getCustomerOrders(orderSearch);
	}
	async getCustomerLastOrder(customerId: number, siteId: number, printProductIds: number[]): Promise<Order> {
		return await this.orderClient.getCustomerLastOrder(new GetCustomerLastOrderQuery({
			customerId,
			siteId,
			productIds: printProductIds
		}));
	}

	async getDistributionsWithRelatedOrders(query: GetCustomerDistributionsQuery): Promise<CustomerDistributionWithRelatedOrders[]> {
		return await this.orderClient.getDistributionsWithRelatedOrders(query);
	}
	async getOrdersCount(orderQuery: OrdersQuery): Promise<number> {
		return await this.reportsClient.getExtendedOrderItemCounts(orderQuery);
	}
	async getOrders(orderQuery: OrdersQuery): Promise<ExtendedOrderItem[]> {
		return await this.reportsClient.getExtendedOrderItem(orderQuery);
	}

	async getOrder(orderId: number): Promise<Order> {
		return await this.orderClient.getOrder(orderId);
	}

	async getOrderHistory(orderId: number): Promise<Audit[]> {
		return await this.orderClient.auditOrder(orderId);
	}

	async getOrderItem(orderItemId: number): Promise<OrderItem> {
		return await this.orderClient.getOrderItem(orderItemId);
	}

	async getOrderItemHistory(orderItemId: number): Promise<OrderItemAudit> {
		return await this.orderClient.auditOrderItem(orderItemId);
	}

	async getFrontImage(orderItemId: number): Promise<Blob | undefined> {
		const result = await this.orderClient.downloadFrontDesign(orderItemId);
		return result?.data;
	}

	async getBackImage(orderItemId: number): Promise<Blob | undefined> {
		const result = await this.orderClient.downloadBackDesign(orderItemId);
		return result?.data;
	}

	async getOrderItemCartProduct(orderItemId: number): Promise<CartProduct> {
		return await this.orderClient.getOrderItemAsCartProduct(orderItemId);
	}

	async updateOrderItemShipping(orderItemId: number, shippingId: number): Promise<boolean> {
		return await this.orderClient.updateOrderItemShipping(orderItemId, shippingId);
	}

	async updateOrderItemDistribution(orderItemId: number, distributionId: number): Promise<boolean> {
		return await this.orderClient.updateOrderItemDistribution(orderItemId, distributionId);
	}

	async getOrderItemDistributions(orderItemId: number): Promise<DropDistribution[]> {
		return await this.orderClient.getOrderItemDistributions(orderItemId);
	}

	async updateOrderItemDropDistribution(orderItemId: number, distributionId: number, dropNumber: number): Promise<boolean> {
		return await this.orderClient.updateOrderItemDropDistribution(new UpdateOrderItemDropDistribution({
			distributionId,
			dropNumber,
			orderItemId
		}));
	}

	async splitOrderItem(customerId: number, orderItemId: number, numberOfDrops: number, weeksBetweenDrops: number): Promise<void> {
		await this.orderClient.splitOrderItemIntoDrops(new SplitOrderItemIntoDropsQuery({
			customerId,
			orderItemId,
			numberOfDrops,
			weeksBetweenDrops
		}));
	}

	async getOrderItemDrops(orderItemId: number): Promise<OrderItemDrop[]> {
		return await this.orderClient.getOrderItemDrops(orderItemId);
	}

	async updateOrderItemDrops(orderId: number, orderItemId: number, query: UpdateOrderItemDrop): Promise<boolean> {
		return await this.orderClient.updateOrderItemDrops(orderId, orderItemId, query);
	}

	getCustomerRemainingOrderBalance(query: GetOrderBalanceQuery): Promise<OrderRemainingBalance[]> {
		return this.paymentClient.getCustomerRemainingOrderBalances(query);
	}

	async getReferencingOrderPayments(customerPaymentId: number): Promise<GroupedOrderPaymentDisplay[]> {
		return await this.paymentClient.getRefOrderPaymentsAssociatedWithCustomerPayment(customerPaymentId);
	}

	async getAssociatedOrderPayments(customerPaymentId: number): Promise<GroupedOrderPaymentDisplay[]> {
		return await this.paymentClient.getOrderPaymentsAssociatedWithCustomerPayment(customerPaymentId);
	}

	async addOrderPayments(orderPayment: OrderPayment): Promise<number> {
		return await this.paymentClient.addOrderPayment(orderPayment);
	}

	async getOrderPayments(orderId: number): Promise<OrderPaymentDisplay[]> {
		return await this.paymentClient.getOrderPayments(orderId);
	}

	async deleteOrderPayment(orderPaymentId: number): Promise<number> {
		return await this.paymentClient.negateOrderPayment(orderPaymentId);
	}

	async getScheduledPaymentsForOrder(orderId: number): Promise<ScheduledPaymentDisplay[]> {
		return await this.paymentClient.getScheduledPaymentsForOrder(orderId);
	}

	async deleteOrder(orderId: number, query: DeleteOrderQuery): Promise<void> {
		await this.orderClient.deleteOrder(orderId, query);
	}

	async restoreOrder(orderId: number): Promise<void> {
		await this.orderClient.restoreOrder(orderId);
	}

	async getOrderNotes(orderId: number): Promise<OrderNote[]> {
		return await this.orderClient.getOrderNotes(orderId);
	}

	async addOrderNote(orderId: number, note: string): Promise<number> {
		return await this.orderClient.addOrderNote(orderId, new OrderNoteRequest( {note: note} ));
	}

	async isListAvailable(orderId: number): Promise<boolean> {
		return await this.orderClient.isListAvailable(orderId);
	}

	async regeneratePurchasedList(orderId: number): Promise<boolean> {
		return await this.orderClient.generatePurchasedList(orderId);
	}

	async getOrderTags(orderId: number): Promise<OrderTag[]> {
		return await this.orderClient.getOrderTags(orderId);
	}

	async saveSalesRepTag(orderId: number, salesRepId?: number): Promise<boolean> {
		return await this.orderClient.saveSalesRepTag(orderId, salesRepId);
	}

	async saveStoreNumberTag(orderId: number, tag: string): Promise<boolean> {
		return await this.orderClient.saveStoreNumberTag(orderId, tag);
	}

	async saveCustomerRewardsNumberTag(orderId: number, tag: string): Promise<boolean> {
		return await this.orderClient.saveCustomerRewardsNumberTag(orderId, tag);
	}

	async listOrderFiles(orderId: number): Promise<string[]> {
		return await this.orderClient.listOrderFiles(orderId);
	}

	async getOrderFile(orderId: number, fileKey: string): Promise<FileResponse | null> {
		return await this.orderClient.getOrderFile(orderId, fileKey);
	}
	async listOrderVersionedFiles(orderId: number): Promise<{ [key: string]: S3ObjectVersion[]; }> {
		return await this.orderClient.listOrderVersionedFiles(orderId);
	}

	async getOrderVersionedFile(fileKey: string, versionId?: string): Promise<FileResponse | null> {
		return await this.orderClient.getOrderVersionedFile(fileKey, versionId);
	}

	/*async getDesignImage(orderItemId: number, filename: string): Promise<Blob | undefined> {
		const result = await this.orderClient.downloadDesignFile(orderItemId, filename);
		return result?.data;
	}*/

	async uploadDesigns(orderItemId: number, formFiles: FileParameter[]): Promise<FileResponse | null> {
		return await this.orderClient.uploadDesigns(orderItemId, formFiles);
	}
	async deleteDesignImage(orderItemId: number, filename: string): Promise<FileResponse | null> {
		return await this.orderClient.deleteDesignFile(orderItemId, filename);
	}

	async getFileOrientation(orderItemId: number, artSide: string): Promise<string> {
		return await this.orderClient.getArtOrientation(orderItemId, artSide);
	}

	async getClickThroughURL(orderId: number, orderItemId: number): Promise<UpdateClickThroughURLQuery> {
		return await this.orderClient.getGoogleClickThroughURL(orderId, orderItemId);
	}

	async updateClickThroughURL(query: UpdateClickThroughURLQuery): Promise<number> {
		return await this.orderClient.updateGoogleClickThroughURL(query);
	}

	async getCustomerOrdersTrackingCount(customerId: number, query: MyOrdersQuery): Promise<number> {
		return await this.orderClient.getCustomerOrdersTrackingCount(customerId, query);
	}
	async getCustomerOrdersTracking(customerId: number, query: MyOrdersQuery): Promise<OrderTracking[]> {
		return await this.orderClient.getCustomerOrdersTracking(customerId, query);
	}

	createInvoice(request: CreateInvoiceRequest): Promise<string> {
		return this.paymentClient.createInvoice(request);
	}

	async getCustomerInvoices(customerId: number): Promise<InvoiceDisplay[]> {
		return await this.paymentClient.getCustomerInvoices(customerId);
	}

	async downloadList(orderId: number): Promise<void> {
		const fileResponse = await this.orderClient.downloadList(orderId);
		if (fileResponse) {
			const downloadURL = window.URL.createObjectURL(fileResponse?.data);
			const link = document.createElement('a');
			link.href = downloadURL;
			link.download = `${orderId}-List.csv`;
			link.click();
		}
	}

	async getOrderItemLinesDisplay(orderItemId: number): Promise<OrderItemLineDisplay[]> {
		return await this.orderClient.getOrderItemLinesDisplay(orderItemId);
	}

	async getQRCodeOrderMeta(request: QRCodeMetaRequest, orderId: number, orderItemId: number): Promise<string> {
		return await this.orderClient.getQRCodeOrderMeta(request, orderId, orderItemId);
	}

	async updateQRCodeOrderMeta(request: QRCodeMetaRequest, orderId: number, orderItemId: number): Promise<string> {
		return await this.orderClient.updateQRCodeOrderMeta(request, orderId, orderItemId);
	}
}
