import { Options } from '@angular-slider/ngx-slider';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PMSaveListQuery } from '@taradel/web-api-client';
import { PmDataService } from 'services/pm-data.service';
import { PmSelectionService } from 'services/pm-selection.service';
import { ToastService } from 'services/toast.service';
import { SelectionSummaryComponent } from './selection-summary/selection-summary.component';
import { ZipcodesAddressComponent } from './zipcodes-address/zipcodes-address.component';
import { SliderComponent } from '@taradel/white-label-common';
import { GooglePlaceResult, mapAddressTradeAreaToGooglePlaceAddress } from 'app/lib/google-maps';

@Component({
	selector: 'app-personalized-mail',
	templateUrl: './personalized-mail.component.html',
	styleUrls: ['./personalized-mail.component.scss']
})
export class PersonalizedMailComponent implements OnInit {

	showSpinner = false;
	customerId!: number;
	distributionId = 0;
	locationAdded = false;
	loading = false;

	ageMin: number = 18;
	ageMax: number = 75;
	ageOptions: Options = {
		step: 1,
		floor: 18,
		ceil: 75
	};

	lorMin: number = 0;
	lorMax: number = 15;
	lorOptions: Options = {
		step: 1,
		floor: 0,
		ceil: 15
	};
	ybhMin: number = 1980;
	ybhMax: number = (new Date()).getFullYear();
	ybhOptions: Options = {
		step: 1,
		floor: 1980,
		ceil: (new Date()).getFullYear()
	};
	@ViewChild('incomeSlider') incomeSlider!: SliderComponent;
	incomeValueMap = new Map<number, {min: string, max: string}>([
		[ 0, {min: '$0', max: '$25,000'} ],
		[ 25000, {min: '$25,000', max: '$50,000'} ],
		[ 50000, {min: '$50,000', max: '$75,000'} ],
		[ 75000, {min: '$75,000', max: '$100,000'} ],
		[ 100000, {min: '$100,000', max: '$150,000'} ],
		[ 150000, {min: '$150,000', max: '$200,000'} ],
		[ 200000, {min: '$200,000', max: '$250,000'} ],
		[ 250000, {min: '$250,000', max: '$250,000+'} ]
	]);
	incomeMin: number = 0;
	incomeMax: number = 5000000;
	incomeOptions: Options = {
		floor: this.incomeMin,
		ceil: this.incomeMax,
		stepsArray: Array.from(this.incomeValueMap.keys()).map(x => {
			return {value: x};
		}),
		translate: (value: number): string => {
			let result = value.toString();
			if (value === this.incomeSlider?.sliderMin) {
				result = this.incomeValueMap.get(value)!.min;
			}
			else if (value === this.incomeSlider?.sliderMax) {
				result = this.incomeValueMap.get(value)!.max;
			}
			return result;
		}
	};
	netWorthValueMap = new Map<number, string>([
		[ 0, '$0' ],
		[ 5000, '$5,000' ],
		[ 10000, '$10,000' ],
		[ 25000, '$25,000' ],
		[ 50000, '$50,000' ],
		[ 100000, '$100,000' ],
		[ 250000, '$250,000' ],
		[ 500000, '$500,000' ],
		[ 1500000, '$500,000+' ]
	]);
	netWorthMin: number = 0;
	netWorthMax: number = 1500000;
	netWorthOptions: Options = {
		floor: 0,
		ceil: 1500000,
		stepsArray: [
			{ value: 0 },
			{ value: 5000 },
			{ value: 10000 },
			{ value: 25000 },
			{ value: 50000 },
			{ value: 100000 },
			{ value: 250000 },
			{ value: 500000 },
			{ value: 1500000 }
		],
		translate: (value: number): string => {
			return this.netWorthValueMap.get(value)!;
		}
	};
	homeValueMap = new Map<number, string>([
		[ 1000, '$1000' ],
		[ 25000, '$25,000' ],
		[ 50000, '$50,000' ],
		[ 75000, '$75,000' ],
		[ 100000, '$100,000' ],
		[ 125000, '$125,000' ],
		[ 150000, '$150,000' ],
		[ 175000, '$175,000' ],
		[ 200000, '$200,000' ],
		[ 225000, '$225,000' ],
		[ 250000, '$250,000' ],
		[ 275000, '$275,000' ],
		[ 300000, '$300,000' ],
		[ 350000, '$350,000' ],
		[ 400000, '$400,000' ],
		[ 450000, '$450,000' ],
		[ 500000, '$500,000' ],
		[ 750000, '$750,000' ],
		[ 999999, '$999,999+' ]
	]);
	homeValueMin: number = 1000;
	homeValueMax: number = 999999;
	homeValueOptions: Options = {
		floor: 1000,
		ceil: 999999,
		stepsArray: [
			{ value: 1000 },
			{ value: 25000 },
			{ value: 50000 },
			{ value: 75000 },
			{ value: 100000 },
			{ value: 125000 },
			{ value: 150000 },
			{ value: 175000 },
			{ value: 200000 },
			{ value: 225000 },
			{ value: 250000 },
			{ value: 275000 },
			{ value: 300000 },
			{ value: 350000 },
			{ value: 400000 },
			{ value: 450000 },
			{ value: 500000 },
			{ value: 750000 },
			{ value: 999999 }
		],
		translate: (value: number): string => {
			return this.homeValueMap.get(value)!;
		}
	};

	vehicleYearMin: number = 0;
	vehicleYearMax: number = (new Date()).getFullYear();
	vehicleYearOptions: Options = {
		step: 1,
		floor: 1980,
		ceil: (new Date()).getFullYear(),
	};
	mileageMin: number = 0;
	mileageMax: number = 250000;
	mileageOptions: Options = {
		floor: 0,
		ceil: 250000,
		stepsArray:[
			{ value: 0 },
			{ value: 10000, legend:'10,000' },
			{ value: 20000, legend:'20,000' },
			{ value: 30000, legend:'30,000' },
			{ value: 40000, legend:'40,000' },
			{ value: 50000, legend:'50,000' },
			{ value: 60000, legend:'60,000' },
			{ value: 70000, legend:'70,000' },
			{ value: 80000, legend:'80,000' },
			{ value: 90000, legend:'90,000' },
			{ value: 100000, legend:'100,000' },
			{ value: 110000, legend:'110,000' },
			{ value: 120000, legend:'120,000' },
			{ value: 130000, legend:'130,000' },
			{ value: 140000, legend:'140,000' },
			{ value: 150000, legend:'150,000' },
			{ value: 160000, legend:'160,000' },
			{ value: 170000, legend:'170,000' },
			{ value: 180000, legend:'180,000' },
			{ value: 190000, legend:'190,000' },
			{ value: 200000, legend:'200,000' },
			{ value: 210000, legend:'210,000' },
			{ value: 220000, legend:'220,000' },
			{ value: 230000, legend:'230,000' },
			{ value: 240000, legend:'240,000' },
			{ value: 250000, legend:'250,000' }
		]
	};

	@ViewChild(SelectionSummaryComponent) pmSelectionsComponent!: SelectionSummaryComponent;
	@ViewChild(ZipcodesAddressComponent) zipcodesAddressComponent!: ZipcodesAddressComponent;
	constructor(
		public pmSelectionService: PmSelectionService,
		private pmDataService: PmDataService,
		private route: ActivatedRoute,
		private router: Router,
		private toastService: ToastService) { }

	ngOnInit() {
		this.route.paramMap.subscribe(async params => {
			try {
				this.customerId = parseInt(params.get('customerId') ?? '0', 10);
				this.distributionId = parseInt(params.get('distributionId') ?? '0', 10);
				if (this.distributionId > 0) {
					await this.loadPersonalizedMailFilters();
				}
			}
			catch {
				this.toastService.showError("There was a problem retrieving the filters", 'Load Error');
			}
			finally {
				this.showSpinner = false;
			}
		});
    }

	locationUpdated(added: boolean) {
		this.locationAdded = added;
	}

	async loadPersonalizedMailFilters(): Promise<void> {
		this.showSpinner = true;
		const distribution = await this.pmDataService.getBuiltList(this.distributionId);
		this.pmSelectionService.query = distribution.filters!;
		const addresses: GooglePlaceResult[] = this.pmSelectionService.query.radiusQuery?.map(mapAddressTradeAreaToGooglePlaceAddress) ?? [];
		this.pmSelectionService.queryUpdates.next(this.pmSelectionService.query);
		this.pmSelectionService.addressUpdates.next(addresses);
		if (addresses.length > 0) {
			this.zipcodesAddressComponent.addressEnabled = false;
		}
		else {
			this.zipcodesAddressComponent.zipcodeEnabled = false;
		}
		this.locationUpdated(true);
		this.showSpinner = false;
	}

	async saveList(listname: string): Promise<void> {
		this.showSpinner = true;
		const distributionId = await this.pmDataService.saveBuiltList(this.customerId, new PMSaveListQuery({
			name: listname,
			query: this.pmSelectionsComponent.query
		}));
		await this.router.navigateByUrl(`/customers/${this.customerId}/distributions/${distributionId}`);
	}
}
