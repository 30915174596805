import { HttpWithAuth } from 'app/http-with-auth';
import { Injectable } from '@angular/core';
import { Coupon, CouponClient } from '@taradel/admin-api-client';
import { environment } from 'environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CouponService {

	private readonly couponClient: CouponClient;

	constructor(http: HttpWithAuth) {
		this.couponClient = new CouponClient(environment.adminApiUrl, http);
	}

	getSiteCoupons(siteId: number): Promise<Coupon[]> {
		return this.couponClient.getSiteCoupons(siteId);
	}

	getCoupon(couponId: number): Promise<Coupon> {
		return this.couponClient.getCoupon(couponId);
	}

	async updateCoupon(coupon: Coupon): Promise<void> {
		await this.couponClient.updateCoupon(coupon);
	}

	createCoupon(coupon: Coupon): Promise<number> {
	  return this.couponClient.createCoupon(coupon);
	}

	async deleteCoupon(couponId: number): Promise<void> {
	  await this.couponClient.deleteCoupon(couponId);
	}
}
