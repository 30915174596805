<app-product-info [productId]="productId"></app-product-info>

<app-spinner-with-overlay [showSpinner]="loading">
    <div *ngIf="auditData.length > 0; else noAuditData" class="col col-auto">
        <app-audit [heading]="'Product Audit History'" [audit]="auditData"></app-audit>
    </div>
    <ng-template #noAuditData>
        <div class="col col-auto text-center">
           <h1>No Audit data found</h1>
        </div>
    </ng-template>
    
</app-spinner-with-overlay>