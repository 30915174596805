import { HttpWithAuth } from 'app/http-with-auth';
import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeValue } from '@angular/platform-browser';
import { CmsClient as WebCmsClient, CmsContent, CmsContentKey, CmsLanguage, FileParameter, FileResponse, SiteLanguage } from '@taradel/web-api-client';
import { CmsClient, CmsContentKeyWithSiteCounts, CmsContentRequest, FilterCmsContentRequest } from '@taradel/admin-api-client';
import { environment } from 'environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ContentService {
	private readonly cmsClient: CmsClient;
	private readonly webCmsClient: WebCmsClient;

	constructor(http: HttpWithAuth,
		private sanitizer: DomSanitizer) {
		this.cmsClient = new CmsClient(environment.adminApiUrl, http);
		this.webCmsClient = new WebCmsClient(environment.webApiUrl, http);
	}

	addContentKey(contentKey: CmsContentKey) {
		return this.cmsClient.createCmsContentKey(contentKey);
	}

	getAllKeys(): Promise<CmsContentKey[]> {
		return this.cmsClient.getCmsContentKeys();
	}

	getContentKeysWithSiteCounts(): Promise<CmsContentKeyWithSiteCounts[]> {
		return this.cmsClient.getContentKeysWithSiteCounts();
	}

	getContentKeyForAllSites(contentKey: string): Promise<CmsContent[]> {
		return this.cmsClient.getContentKeyForAllSites(contentKey);
	}

	updateContentKey(contentKey: CmsContentKey) {
		return this.cmsClient.updateCmsContentKey(contentKey);
	}

	deleteContentKey(contentKey: string) {
		return this.cmsClient.deleteCmsContentKey(contentKey);
	}

	getCmsLanguages(): Promise<CmsLanguage[]> {
		return this.webCmsClient.getCmsLanguages();
	}

	getSupportedLanguages(siteId: number): Promise<SiteLanguage[]> {
		return this.webCmsClient.getAllSupportedLanguages(siteId);
	}

	getSiteContent(siteId: number): Promise<CmsContent[]> {
		return this.cmsClient.getAllCmsContentForSite(siteId);
	}

	async getSectionContent(siteId: number, language: string, contentPrefix: string): Promise<Map<string, SafeValue>> {
		const content = new Map<string, SafeValue>();
		const contentSet = await this.webCmsClient.searchCmsContent(siteId, language, contentPrefix);
		for (let key of contentSet) {
			content.set(key.contentKey?.contentKey!, this.sanitizer.sanitize(SecurityContext.HTML, key.content ?? '') ?? '' as SafeValue);
		}
		return content;
	}

	filterSiteContent(request: FilterCmsContentRequest): Promise<CmsContent[]> {
		return this.cmsClient.filterCmsContent(request);
	}

	deleteSiteContent(siteId: number, contentKey: string) {
		return this.cmsClient.deleteCmsContent(siteId, contentKey);
	}

	saveSiteContent(content: CmsContentRequest) {
		return this.cmsClient.upsertCmsContent(content);
	}

	async exportSiteContent(siteId: number): Promise<FileResponse | null> {
		return await this.cmsClient.exportCmsContent(siteId);
	}

	async importSiteContent(siteId: number, file: FileParameter): Promise<void> {
		await this.cmsClient.importCmsContent(siteId, file);
	}
}
