import { Injectable } from '@angular/core';
import { AddressTradeArea, Filters, PersonalizedMailQuery } from '@taradel/web-api-client';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { KeyWithValueType } from './equifax-selection.service';
import { GooglePlaceResult } from 'app/lib/google-maps';

export type PMAddressKey = KeyWithValueType<PersonalizedMailQuery, AddressTradeArea[] | undefined>;
export type PMFilterKey = KeyWithValueType<PersonalizedMailQuery, Filters | undefined>;
export type PMFilterListKey = KeyWithValueType<Filters, string[] | undefined>;
export type PMListKey = KeyWithValueType<PersonalizedMailQuery, string[] | undefined>;
export type PMFilterBooleanKey = KeyWithValueType<Filters, boolean | undefined>;
export type PMSliderKey = KeyWithValueType<PersonalizedMailQuery, string | undefined>;

export const pmZipcodeValues = {
	zipCodes: [] as { key: string; value: string }[],
};
export const pmListValues = {
	//zipCodes: [] as { key: string, value: string }[],
	age: [
		{ key: '18', value: '18' },
		{ key: '19', value: '19' },
		{ key: '20', value: '20' },
		{ key: '21', value: '21' },
		{ key: '22', value: '22' },
		{ key: '23', value: '23' },
		{ key: '24', value: '24' },
		{ key: '25', value: '25' },
		{ key: '26', value: '26' },
		{ key: '27', value: '27' },
		{ key: '28', value: '28' },
		{ key: '29', value: '29' },
		{ key: '30', value: '30' },
		{ key: '31', value: '31' },
		{ key: '32', value: '32' },
		{ key: '33', value: '33' },
		{ key: '34', value: '34' },
		{ key: '35', value: '35' },
		{ key: '36', value: '36' },
		{ key: '37', value: '37' },
		{ key: '38', value: '38' },
		{ key: '39', value: '39' },
		{ key: '40', value: '40' },
		{ key: '41', value: '41' },
		{ key: '42', value: '42' },
		{ key: '43', value: '43' },
		{ key: '44', value: '44' },
		{ key: '45', value: '45' },
		{ key: '46', value: '46' },
		{ key: '47', value: '47' },
		{ key: '48', value: '48' },
		{ key: '49', value: '49' },
		{ key: '50', value: '50' },
		{ key: '51', value: '51' },
		{ key: '52', value: '52' },
		{ key: '53', value: '53' },
		{ key: '54', value: '54' },
		{ key: '55', value: '55' },
		{ key: '56', value: '56' },
		{ key: '57', value: '57' },
		{ key: '58', value: '58' },
		{ key: '59', value: '59' },
		{ key: '60', value: '60' },
		{ key: '61', value: '61' },
		{ key: '62', value: '62' },
		{ key: '63', value: '63' },
		{ key: '64', value: '64' },
		{ key: '65', value: '65' },
		{ key: '66', value: '66' },
		{ key: '67', value: '67' },
		{ key: '68', value: '68' },
		{ key: '69', value: '69' },
		{ key: '70', value: '70' },
		{ key: '71', value: '71' },
		{ key: '72', value: '72' },
		{ key: '73', value: '73' },
		{ key: '74', value: '74' },
		{ key: '75', value: '75' }
	],
	children_Present:[
		{ key: '1', value: 'Present' },
		{ key: '0', value: 'Not Present' }
	],
	gender:[
		{ key: 'M', value: 'Male' },
		{ key: 'F', value: 'Female' }
	],
	home_Owner_Renter:[
		{ key: 'O', value: 'Homeowner' },
		{ key: 'R', value: 'Renter' }
	],
	marital_Status:[
		{ key: 'M,A', value: 'Married' },
		{ key: 'S,B', value: 'Single' }
	],
	education_Individual:[
		{ key: 'A', value: 'Completed High School' },
		{ key: 'B', value: 'Completed College' },
		{ key: 'C', value: 'Completed Graduate School' },
		{ key: 'D', value: 'Attended Vocational / Technical' }
	],
	length_Of_Residence: [] as { key: string; value: string }[],
	home_Built_Year: [] as { key: string; value: string }[],
	dwelling_Type:[
		{ key: 'S', value: 'Single Family' },
		{ key: 'M', value: 'Multiple Family' }
	],
	income_Code:[
		{ key: '1,2,3,4', value: '0' },
		{ key: '5,6,7,8,9', value: '25000' },
		{ key: 'A,B,C,D', value: '50000' },
		{ key: 'E', value: '75000' },
		{ key: 'F', value: '100000' },
		{ key: 'G,H', value: '150000' },
		{ key: 'I', value: '200000' },
		{ key: 'J,K,L,M,N', value: '250000' }
	],
	networth_Code:[
		{ key: 'A', value: '0' },
		{ key: 'B', value: '5000' },
		{ key: 'C', value: '10000' },
		{ key: 'D', value: '25000' },
		{ key: 'E', value: '50000' },
		{ key: 'F', value: '100000' },
		{ key: 'G', value: '250000' },
		{ key: 'H', value: '500000' },
		{ key: 'I', value: '5000000' }
	],
	home_Value_Code:[
		{ key: 'A', value: '1000' },
		{ key: 'B', value: '25000' },
		{ key: 'C', value: '50000' },
		{ key: 'D', value: '75000' },
		{ key: 'E', value: '100000' },
		{ key: 'F', value: '125000' },
		{ key: 'G', value: '150000' },
		{ key: 'H', value: '175000' },
		{ key: 'I', value: '200000' },
		{ key: 'J', value: '225000' },
		{ key: 'K', value: '250000' },
		{ key: 'L', value: '275000' },
		{ key: 'M', value: '300000' },
		{ key: 'N', value: '350000' },
		{ key: 'O', value: '400000' },
		{ key: 'P', value: '450000' },
		{ key: 'Q', value: '500000' },
		{ key: 'R', value: '750000' },
		{ key: 'S', value: '999999' }
	]
};

export const pmBooleanValues = {
	credit_Card_User: 'Only Credit Card Users',
	donor_Charitable: 'Yes',
	reading_General: 'Book Reader',
	high_Tech_General: 'Technology',
	gardening: 'Gardening',
	photography: 'Photography',
	sewing_Knitting_Needlework: 'Sewing and Knitting',
	woodworking: 'woodworking',
	travel_Cruises: 'Cruise Travel',
	travel_Domestic: 'Domestic Travel',
	travel_Grouping: 'General Travel',
	travel_International: 'International Travel',
	exercise_Aerobic: 'Aerobics',
	exercise_Running_Jogging: 'Running',
	exercise_Walking: 'Walking',
	outdoor_Boating_Sailing: '',
	outdoor_Enthusiast_General: 'General Outdoor',
	outdoor_Fishing: 'Fishing',
	outdoor_Hunting_Shooting: 'Hunting',
	sports_Skiing: 'Winter / Snow Sports',
	cooking_General: 'General Cooking',
	cooking_Gourmet: 'Gourmet Food',
	food_Wines: 'Wine',
	foods_Natural: 'Natural Foods',
	dieting_Weight_Loss: 'Dieting / Weight Loss',
	health_Medical: 'General Health / Medical',
	self_Improvement: 'Self Improvement',
	spectator_NASCAR: 'Auto Racing',
	spectator_Sports_Baseball: 'Baseball',
	spectator_Sports_Football: 'Football',
	spectator_Sports_General: 'General',
	sports_Golf: 'Golf',
	sports_Tennis: 'Tennis',
	home_Improvement: 'Home Improvement Services',
	home_Improvement_Diy: 'Do-It-Yourself Home Improvement'
};

@Injectable({
  providedIn: 'root'
})
export class PmSelectionService {
	public query = new PersonalizedMailQuery();
	addresses?: GooglePlaceResult[];
	queryUpdates = new BehaviorSubject<PersonalizedMailQuery>(new PersonalizedMailQuery());
	addressUpdates = new BehaviorSubject<GooglePlaceResult[]>([]);
	viewOnly = false;
	constructor() { }

	clear() {
		this.query = new PersonalizedMailQuery();
		this.queryUpdates.next(this.query);
		this.addresses = [];
		this.addressUpdates.next(this.addresses);
	}

	getList(field: string) {
		let data = pmZipcodeValues[field as PMListKey];
		if (data === undefined) {
			data = pmListValues[field as PMFilterListKey];
		}
		return data;
	}
	getSelections(field: string) {
		let data = this.query[field as PMListKey];
		if (data === undefined && this.query.filters !== undefined) {
			data = this.query.filters![field as PMFilterListKey];
		}
		return data;
	}

	addToList(field: string, item: string) {
		const hasFilters = pmListValues[field as PMFilterListKey] !== undefined;
		if (this.query.filters === undefined && hasFilters) {
			this.query.filters = new Filters();
		}
		if (hasFilters) {
			if (this.query.filters![field as PMFilterListKey] === undefined) {
				this.query.filters![field as PMFilterListKey] = [];
			}
			const index = this.query.filters![field as PMFilterListKey]?.indexOf(item);
			if (index === undefined || index === -1) {
				this.query.filters![field as PMFilterListKey]!.push(item);
				this.queryUpdates.next(this.query);
			}
		}
	}

	addMultipleToList(field: string, item: string[]) {
		if (this.query[field as PMListKey] === undefined) {
			this.query[field as PMListKey] = [];
		}
		let added = false;
		item.forEach(x => {
			if (this.query[field as PMListKey]!.indexOf(x) === -1) {
				added = true;
				this.query[field as PMListKey]!.push(x);
			}
		});
		if (added) {
			this.queryUpdates.next(this.query);
		}
	}

	removeFromList(field: string, item: string) {
		const hasFilters = pmListValues[field as PMFilterListKey] !== undefined;
		let list = this.query[field as PMListKey];
		if (hasFilters && list === undefined) {
			list = this.query.filters![field as PMFilterListKey];
		}
		if (list !== undefined) {
			const indexToRemove = list.indexOf(item);
			if (indexToRemove !== -1) {
				list.splice(indexToRemove, 1);
				this.queryUpdates.next(this.query);
			}
		}
	}

	replaceList(field: string, item: string[]) {
		if (this.query.filters === undefined) {
			this.query.filters = new Filters();
		}
		const values = this.getList(field);
		let finalData: string[] = [];
		if (values !== undefined && values.length > 0) {
			item.map(x => {
				const value = values.find(y => y.value === x);
				if (value !== undefined) {
					finalData.push(value.key);
				}
				else {
					finalData.push(x);
				}
			});
		}
		else {
			finalData = item;
		}
		this.query.filters[field as PMFilterListKey] = finalData;
		this.queryUpdates.next(this.query);
	}

	getBooleanList(field: string) {
		return pmBooleanValues[field as PMFilterBooleanKey];
	}
	setBoolean(field: string) {
		if (this.query.filters === undefined) {
			this.query.filters = new Filters();
		}
		this.query.filters[field as PMFilterBooleanKey] = true;
		this.queryUpdates.next(this.query);
	}

	removeBoolean(field: string) {
		this.query.filters![field as PMFilterBooleanKey] = undefined;
		this.queryUpdates.next(this.query);
	}

	setAddressTradeArea(field: string, address: GooglePlaceResult, item: AddressTradeArea) {
		if (this.query[field as PMAddressKey] === undefined) {
			this.query[field as PMAddressKey] = [];
		}
		if (this.addresses === undefined) {
			this.addresses = [];
		}
		const index = this.query[field as PMAddressKey]?.findIndex(x => x.latitude === item.latitude && x.longitude === item.longitude
			&& x.areaType === item.areaType && x.areaSize === item.areaSize);
		if (index === undefined || index === -1) {
			this.query[field as PMAddressKey]!.push(item);
			this.addresses.push(address);
			this.queryUpdates.next(this.query);
			this.addressUpdates.next(this.addresses);
		}
	}

	removeAddressTradeArea(field: string, placeId: string, tradeArea: AddressTradeArea) {
		let index = this.addresses?.findIndex(y => y.place_id === placeId);
		this.addresses?.splice(index!, 1);
		index = this.query[field as PMAddressKey]?.findIndex(y => y.latitude === tradeArea.latitude && y.longitude === tradeArea.longitude &&
			y.areaSize === tradeArea.areaSize && y.areaType === tradeArea.areaType);
		this.query[field as PMAddressKey]?.splice(index!, 1);
		this.queryUpdates.next(this.query);
		this.addressUpdates.next(this.addresses!);
	}
}
