import { HttpWithAuth } from 'app/http-with-auth';
import { Injectable } from '@angular/core';
import { PriceCalculatorRequest, PriceCalculatorResponse, PricingClient as WebPricingClient, ProductPricingRequest,
	ProductPricingResponse } from '@taradel/web-api-client';
import { PmodContainerCapacity, PmodContainerZoneCost, PriceMatrix, PriceMatrixSiteMod, PricingClient, UpdateUSelectProductsPostageRequest } from '@taradel/admin-api-client';
import { environment } from 'environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PricingService {

	private readonly pricingClient: PricingClient;
	private readonly webPricingClient: WebPricingClient;

	constructor(http: HttpWithAuth) {
	  this.pricingClient = new PricingClient(environment.adminApiUrl, http);
	  this.webPricingClient = new WebPricingClient(environment.webApiUrl, http);
	}

	getProductPriceTiers(productId: number): Promise<PriceMatrix[]> {
		return this.pricingClient.getProductPriceTiers(productId);
	}

	addProductPriceTier(tier: PriceMatrix): Promise<number> {
		return this.pricingClient.addProductPriceTier(tier);
	}

	updateProductPriceTiers(tiers: PriceMatrix[]): Promise<number> {
		return this.pricingClient.updateProductPriceTiers(tiers);
	}

	removeProductPriceTier(tier: PriceMatrix): Promise<number> {
		return this.pricingClient.removeProductPriceTier(tier);
	}

	updateTierDiscount(priceMatrixSiteMod: PriceMatrixSiteMod): Promise<number> {
		return this.pricingClient.addOrUpdateTierDiscount(priceMatrixSiteMod);
	}

	getPriceCalculator(request: PriceCalculatorRequest): Promise<PriceCalculatorResponse> {
		return this.webPricingClient.calculator(request);
	}

	addPmodContainerCapacity(pmodContainerCapacity: PmodContainerCapacity): Promise<number> {
		return this.pricingClient.addPmodContainerCapacity(pmodContainerCapacity);
	}

	editPmodContainerCapacity(pmodContainerCapacity: PmodContainerCapacity): Promise<number> {
		return this.pricingClient.editPmodContainerCapacity(pmodContainerCapacity);
	}

	getPmodContainerCapacity(): Promise<PmodContainerCapacity[]> {
		return this.pricingClient.getPmodContainerCapacity();
	}

	getPmodContainerZoneCost(): Promise<PmodContainerZoneCost[]> {
		return this.pricingClient.getPmodContainerZoneCost();
	}

	editPmodContainerZoneCost(pmodContainerZoneCost: PmodContainerZoneCost): Promise<number> {
		return this.pricingClient.editPmodContainerZoneCost(pmodContainerZoneCost);
	}

	addPmodContainerZoneCost(pmodContainerZoneCost: PmodContainerZoneCost): Promise<number> {
		return this.pricingClient.addPmodContainerZoneCost(pmodContainerZoneCost);
	}

	getProductPricing(siteId: number, customerId: number, productId: number): Promise<ProductPricingResponse[]> {
		return this.webPricingClient.getProductPricing(new ProductPricingRequest( {
			siteId,
			customerId,
			baseProductId: productId
		}));
	}

	updateUSelectProductsPostage(request: UpdateUSelectProductsPostageRequest) {
		return this.pricingClient.updateUSelectProductsPostage(request);
	}
}
