<app-spinner-with-overlay [showSpinner]="orderLayoutService.loadingOrder || loading">
	<div class="card p-3 mb-3">
		<div class="row" *ngIf="(orderLayoutService.selectedOrder?.financeFee ?? 0) === 0 && orderLayoutService.orderBalance.value > 0 && authService.organizationId === 1">
			<div class="col-auto">
				<a [routerLink]="['/customers', orderLayoutService.selectedCustomer?.customerID, 'payments', 'create']"
				[state]="{ orderId: orderLayoutService.orderId }"
				class="btn btn-small btn-primary">
				Create Payment
				</a>
			</div>
		</div>
		<table class="table table-hover">
			<thead>
				<tr>
					<th>Payment Id</th>
					<th>Customer Payment Id</th>
					<th>Note</th>
					<th>Method</th>
					<th>Date</th>
					<th>Created By</th>
					<th>Amount</th>
					<th *ngIf="authService.organizationId !== 1"></th>
				</tr>
			</thead>
			<tbody>
				<ng-container *ngFor="let payment of orderPayments; let i = index;">
					<tr>
						<td>{{payment.orderPaymentId}}</td>
						<td>
							<a [routerLink]="['/customers', payment.customerPayment?.customerId, 'payments', payment.customerPaymentId]" class="btn btn-link p-0">{{ payment.customerPaymentId }}</a>
						</td>
						<td>{{payment.customerPayment?.note}}</td>
						<td>{{payment.customerPayment?.paymentType}}</td>
						<td>{{orderLayoutService.formatDate(payment.createdDate)}}</td>
						<td>{{payment.createdBy}}</td>
						<td>{{payment.amount | currency }}</td>
						<td>
							<button *ngIf="authService.organizationId === 1 && paymentHasBalance(payment.customerPaymentId)" class="btn btn-danger btn-sm" type="button" title="Reverse" (click)="openModal(reverseOrderPaymentModal, payment)"><i class="fa fa-history"></i></button>
						</td>
					</tr>

				</ng-container>
			</tbody>
		</table>
		<div class="row mb-3">
			<div class="col">
				<a [routerLink]="['/customers', this.orderLayoutService.selectedCustomer?.customerID, 'payments']" class="btn btn-link p-0">See all customer payments</a>
			</div>

		</div>
	</div>

	<ng-container *ngIf="scheduledPayments && scheduledPayments.length">
		<div class="card p-3 mb-3">
			<h5>Order Scheduled Payments</h5>
			<table class="table table-hover">
				<thead>
					<tr>
						<th>Scheduled Payment Id</th>
						<th>Recurring Payment Id</th>
						<th>Amount</th>
						<th>Created By</th>
						<th>Payment Due Date</th>
					</tr>
				</thead>
				<tbody>
					<ng-container *ngFor="let scheduledPayment of scheduledPayments; let i = index;">
						<tr>
							<td>
								{{ scheduledPayment.scheduledPaymentId }}
							</td>
							<td>
								{{ scheduledPayment.recurringPaymentId }}
							</td>
							<td>
								{{ scheduledPayment.amount | currency }}
							</td>
							<td>
								{{ scheduledPayment.createdBy }}
							</td>
							<td>
								{{ scheduledPayment.paymentDueDate | date }}
							</td>
						</tr>
					</ng-container>
				</tbody>
			</table>
		</div>
	</ng-container>
</app-spinner-with-overlay>

<ng-template #reverseOrderPaymentModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Reverse Order Payment {{ selectedOrderPayment?.orderPaymentId }}</h4>
		<button type="button" class="btn-close" aria-describedby="modal-title"
			(click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<p class="lead">Are you sure you want to reverse the order payment <span class="text-danger">"{{ selectedOrderPayment?.orderPaymentId }}"</span>?</p>
		<p class="lead text-danger"><strong>This operation can not be undone.</strong></p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-text" (click)="closeModal()">Cancel</button>
		<button type="button" class="btn btn-primary btn-lg" (click)="deleteOrderPayment()">Reverse
			Order Payment </button>
	</div>
</ng-template>
