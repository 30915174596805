<app-spinner-with-overlay [showSpinner]="loading">
	<div class="card border-0 p-3">
		<div *ngIf="hasRemainingOrderBalances && authService.organizationId === 1" class="row mb-3 d-flex justify-content-end">
			<div class="col-auto">
				<a class="btn btn-success" [routerLink]="['/customers', customerId, 'recurring-payments', 'create-payment']">Create Recurring Payment</a>
			</div>
		</div>
		<ng-container *ngIf="recurringPayments.length === 0 && !loading">
			<app-alert-box alertMode="info">
				No recurring payments found.
			</app-alert-box>
		</ng-container>

		<ng-container *ngIf="recurringPayments.length > 0">
			<div class="table-responsive">
				<table class="table table-hover table-panel">
					<thead>
						<tr>
							<th>Recurring Payment Id</th>
							<th>Next Payment Due</th>
							<th>Customer Profile Id</th>
							<th>Created Date</th>
							<th>Created By</th>
							<th>Customer Id</th>
							<th>Payment Profile Id</th>
							<th>Status</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<ng-container *ngFor="let recurringPayment of recurringPayments; let i = index;">
							<tr>
								<td>
									{{ recurringPayment.recurringPaymentId }}
								</td>
								<td>
									{{ recurringPayment.nextDue?.paymentDueDate | date }}
								</td>
								<td>
									{{ recurringPayment.paymentProfileId }}
								</td>
								<td>
									{{ recurringPayment.createdDate | date }}
								</td>
								<td>
									{{ recurringPayment.createdBy }}
								</td>
								<td>
									{{ recurringPayment.customerId }}
								</td>
								<td class="p-1">
									<select type="select" class="form-select" [(ngModel)]="recurringPayment.paymentProfileId" [disabled]="authService.organizationId !== 1">
										<option *ngFor="let x of customerPaymentProfiles" value="{{x.customerPaymentProfileId}}">
											{{(x.creditCardMaskedData?.cardType ?? 'Checking')}} - {{(x.creditCardMaskedData?.maskedCard ?? x.achMaskedData?.accountNumber) }} - {{x.fullname}}
										</option>
									</select>
								</td>
								<td class="p-1">
									<select class="form-select w-auto" name="status" [disabled]="authService.organizationId !== 1" [(ngModel)]="recurringPayment.status">
										<option value="Active">Active</option>
										<option value="Paused">Paused</option>
										<option value="Canceled">Canceled</option>
										<option value="Complete">Complete</option>
									</select>
								</td>
								<td>
									<div class="row">
										<div class="col-auto">
											<button *ngIf="viewIndex !== i" type="button" class="btn btn-primary" (click)="viewIndex = i;"
											data-toggle="tooltip" data-placement="top" title="Open quote">
												<span class="fas fa-plus"></span>
											</button>
											<button *ngIf="viewIndex === i" type="button" class="btn btn-primary" (click)="viewIndex = undefined"
											data-toggle="tooltip" data-placement="top" title="Close">
												<span class="fas fa-minus"></span>
											</button>
										</div>
										<div class="col-auto">
											<button [disabled]="authService.organizationId !== 1" type="button" class="btn btn-primary" (click)="updateRecurringPayment(recurringPayment)"
											data-toggle="tooltip" data-placement="top" title="Update">
												Update
											</button>
										</div>
									</div>
								</td>
							</tr>
							<tr *ngIf="i === viewIndex && !!recurringPayment.scheduledPaymentsDisplay" class="line-items-row">
								<td colspan="7">
									<div class="card m-2">
										<div class="card-body">
											<div class="row">
												<div class="col-auto">
													<section *ngFor="let scheduledPayment of recurringPayment.scheduledPaymentsDisplay" class="row my-2">
														<p>
															Scheduled Payment Id: <strong>{{ scheduledPayment.scheduledPaymentId }}</strong>
														</p>
														<p *ngIf="!!getSchedulePaymentAttempt(scheduledPayment)">
															Scheduled Payment Attempt Id:
															<strong>
																<a [routerLink]="['/customers', customerId, 'payment-attempts', getSchedulePaymentAttempt(scheduledPayment)?.customerPaymentAttemptId]" class="btn btn-link p-0">{{ getSchedulePaymentAttempt(scheduledPayment)?.customerPaymentAttemptId }}</a>
															</strong>
														</p>
														<p>
															Payment Status: <span [ngClass]="(getSchedulePaymentAttempt(scheduledPayment)?.customerPaymentAttempt?.status ?? 'Pending')">{{(getSchedulePaymentAttempt(scheduledPayment)?.customerPaymentAttempt?.status ?? 'Pending')}}</span>
														</p>
														<p>
															Created By: <strong>{{ scheduledPayment.createdBy }}</strong>
														</p>
														<p>
															Payment Due Date:
															<input #dueDate="ngModel"  type="date" [min]="today | date:'yyyy-MM-dd'"
															[ngModel]="scheduledPayment.paymentDueDate | date:'yyyy-MM-dd'"
															[disabled]="authService.organizationId !== 1"
															(change)="dueDateSelected(scheduledPayment, dueDate.value)">
														</p>
														<p>
															Amount: <strong>{{ scheduledPayment.amount | currency:'USD' }}</strong>
														</p>
														<p>
															Order Id: <a [routerLink]="['/orders', scheduledPayment.orderId, 'summary']" class="btn btn-link p-0"><strong>{{ scheduledPayment.orderId }}</strong></a>
														</p>
														<p>
															<button type="button" class="btn btn-primary" (click)="updateScheduledPayment(scheduledPayment)"
															data-toggle="tooltip" data-placement="top" title="Update" [disabled]="authService.organizationId !== 1">
																Update
															</button>
														</p>
													</section>
												</div>
											</div>
										</div>
									</div>
								</td>
							</tr>
						</ng-container>

					</tbody>
				</table>
			</div>
		</ng-container>
	</div>
</app-spinner-with-overlay>
