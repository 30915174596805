import { HttpWithAuth } from 'app/http-with-auth';
import { Injectable } from '@angular/core';
import { DeliveryDatesClient, USelectDesignTurnaround, USelectInductionTurnaround } from '@taradel/admin-api-client';
import { environment } from 'environment';
import { lastValueFrom } from 'rxjs';

export enum DesignType  {
	NotSet = 0,
	Upload = 1,
	Template = 2,
	ProfessionalDesign = 3,
	DesignHuddle = 4,
	Omitted = 5
}

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {
	private readonly deliveryDatesClient: DeliveryDatesClient;

	constructor(http: HttpWithAuth) {
		this.deliveryDatesClient = new DeliveryDatesClient(environment.adminApiUrl, http);
	}

	getUSelectDesignTurnaround(uSelectId: number): Promise<USelectDesignTurnaround[]> {
		return this.deliveryDatesClient.getUSelectDesignTurnaround(uSelectId);
	}

	updateUSelectDesignTurnaround(uSelectId: number, uSelectDesignTurnarounds: USelectDesignTurnaround[]): Promise<boolean> {
		return this.deliveryDatesClient.updateUSelectDesignTurnaround(uSelectId, uSelectDesignTurnarounds);
	}

	getUSelectInductionTurnaround(): Promise<USelectInductionTurnaround[]> {
		return this.deliveryDatesClient.getAllUSelectInductionTurnaround();
	}

	updateUSelectInductionTurnaround(uSelectInductionTurnarounds: USelectInductionTurnaround[]): Promise<boolean> {
		return this.deliveryDatesClient.updateUSelectInductionTurnaround(uSelectInductionTurnarounds);
	}
}
