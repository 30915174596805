import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Audit } from '@taradel/admin-api-client';
import { ProductsService } from 'services/products.service';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-product-audit-data',
	templateUrl: './product-audit-data.component.html',
	styleUrl: './product-audit-data.component.scss'
})
export class ProductAuditDataComponent {
productId = 0;
	auditData: Audit[] = [];
	loading = true;
	constructor(
		private productService: ProductsService, 
		route: ActivatedRoute,
		toastService: ToastService) {
		route.paramMap.subscribe(async params => {
			try {
				this.productId = parseInt(params.get('productId') ?? '0', 10);
				this.auditData = await this.productService.getProductAuditData(this.productId);
			}
			catch(error) {
				toastService.showError('There was an error getting product audit data');
			}
			finally {
				this.loading = false;
			}
		});
	}
}
