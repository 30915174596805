
<ng-container *ngIf="!!orderItem">
	<div class="card">
		<div class="card-header d-flex justify-content-between" [ngClass]="{ 'bg-danger': f.deleted.value === true || f.deletedFromOrder.value === true }">
			<h5>{{ orderItem.orderItemId }} - {{ getProductName(orderItem.productId) }}
				<span *ngIf="f.deleted.value === true">$0.00</span>
				<span *ngIf="f.deleted.value !== true">{{ orderItem.total | currency }}</span>
			</h5>
			<div>
				<button *ngIf="f.deletedFromOrder.value === false && f.deleted.value === false && !hasPurchaseOrder" class="btn btn-sm btn-danger me-2" data-toggle="tooltip" data-placement="top" title="Mark As Deleted" (click)="markItemAsDeleted()">
					<i class="fa fa-trash"></i>
				</button>
				<button *ngIf="f.deleted.value === true && f.deletedFromOrder.value === false && !hasPurchaseOrder" class="btn btn-sm btn-primary" data-toggle="tooltip" data-placement="top" title="Unmark As Deleted" (click)="removeDeletedFlag()">
					<i class="fa fa-undo"></i>
				</button>
				<button *ngIf="f.deletedFromOrder.value === false && f.deleted.value === false" class="btn btn-sm btn-primary" data-toggle="tooltip" data-placement="top" title="Edit" (click)="openModal(updateOrderItemModal)">
					<i class="fa fa-edit"></i>
				</button>
			</div>
		</div>
		<div class="card-body">
			<div class="row">
				<div class="col-md-10 col-12 mx-auto">
					<form [formGroup]="orderItemForm">
						<ng-container formArrayName="newLineItems">
							<div *ngFor="let line of fItems; let i = index">
								<div class="row mb-3">
									<div class="col">
										<div class="row">
											<div class="col">
												<div class="row mb-3 d-flex justify-content-between">
													<div class="col-auto">
														{{ line.get('description')?.value }} {{ line.get('amount')?.value | currency }}
													</div>
													<div *ngIf="!isUneditable(line.value) && f.deleted.value !== true && f.deletedFromOrder.value === false" class="col-auto">
														<button class="btn btn-sm btn-primary" data-toggle="tooltip" data-placement="top" title="Refund"
														(click)="initiateRefund(line.value, i + 1)" [disabled]="inEditMode">
															<i class="fa fa-undo"></i>
														</button>
													</div>
												</div>
											</div>
										</div>
										<ng-container *ngIf="line.get('deletedOrderItemLine')?.value && edit.orderItemLineId.value !== (i + 1)">
											<div class="row ms-3">
												<div class="col-auto">
													{{ line.get('deletedOrderItemLine')?.value.description }} {{ line.get('deletedOrderItemLine')?.value.amount | currency }}
													<button *ngIf="!line.get('newOrderItemLine')?.value && f.deleted.value !== true" class="btn btn-sm btn-danger" data-toggle="tooltip" data-placement="top" title="Remove Changes"
													(click)="removeChanges(line.get('orderItemLineId')?.value)">
														<i class="fa fa-trash"></i>
													</button>
												</div>
											</div>
										</ng-container>
										<ng-container *ngIf="line.get('newOrderItemLine')?.value && edit.orderItemLineId.value !== line.get('orderItemLineId')?.value">
											<div class="row ms-3">
												<div class="col-auto">
													{{ line.get('newOrderItemLine')?.value.description }} {{ line.get('newOrderItemLine')?.value.amount | currency }}
													<button class="btn btn-sm btn-danger" data-toggle="tooltip" data-placement="top" title="Remove Changes"
													(click)="removeChanges(line.get('orderItemLineId')?.value)">
														<i class="fa fa-trash"></i>
													</button>
												</div>
											</div>
										</ng-container>
										<ng-container *ngIf="edit.orderItemLineId.value && edit.orderItemLineId.value === i + 1">
											<form [formGroup]="orderItemLineForm">
												<div class="row mb-3 d-flex justify-content-between">
													<div class="col-auto">
														{{ edit.description.value ?? '' }}
														<span>
															<div class="input-group">
																<div class="input-group-text">-</div>
																<input type="number" class="form-control" formControlName="amount" placeholder="edit.amount.value" />
															</div>
														</span>
													</div>
													<div class="col-auto">
														<button *ngIf="!line.get('newOrderItemLine')?.value" class="btn btn-sm btn-danger" data-toggle="tooltip" data-placement="top" title="Cancel"
														(click)="cancelRefund()">
															<i class="fa fa-times"></i>
														</button>
														<button class="btn btn-sm btn-success" data-toggle="tooltip" data-placement="top" title="Save Changes"
														(click)="saveChanges()">
															<i class="fa fa-save"></i>
														</button>
													</div>
												</div>
											</form>
										</ng-container>
									</div>
								</div>
							</div>
						</ng-container>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-container>

<ng-template #updateOrderItemModal>
	<app-confirm-modal title="Update order item confirmation" confirmText="confirm" (confirmAction)="emitUpdateOrderItem()"
		(closeModal)="closeModal()">
		<p *ngIf="hasPurchaseOrder">
			<b *ngIf="hasPurchaseOrder" class="bg-warning">Warning: You are changing an item already sent to print. </b>
			<a [routerLink]="['/orders', orderItem?.orderId, 'details', orderItem?.orderItemId]" target="_blank">Click here to view the order item</a>
			<br/> <br/>
		</p>
		<p>
			Changes are not automatically sent to the printer. Please communicate these changes to the printer directly
		</p>
	</app-confirm-modal>
</ng-template>