import { HttpWithAuth } from 'app/http-with-auth';
import { Injectable } from '@angular/core';
import { PrintAndShipPricingClient as WebPrintAndShipPricingClient, PrintAndShipClient as WebPrintAndShipClient, ShippingAndTimeRequest, ShippingAndTimeResponse } from '@taradel/web-api-client';
import { CategorySearch, PrintAndShipCategory, PrintAndShipClient, PrintAndShipPricingClient } from '@taradel/admin-api-client';
import { environment } from 'environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrintAndShipService {
	private readonly printAndShipClient: PrintAndShipClient;
	private readonly printAndShipPricingClient: PrintAndShipPricingClient;


	private readonly webPrintAndShipClient: WebPrintAndShipClient;
	private readonly webPrintAndShipPricingClient: WebPrintAndShipPricingClient;

  	constructor(http: HttpWithAuth) {
		this.printAndShipClient = new PrintAndShipClient(environment.adminApiUrl, http);
		this.printAndShipPricingClient = new PrintAndShipPricingClient(environment.adminApiUrl, http);

		this.webPrintAndShipClient = new WebPrintAndShipClient(environment.webApiUrl, http);
		this.webPrintAndShipPricingClient = new WebPrintAndShipPricingClient(environment.webApiUrl, http);
	}

	async getShippingAndTime(request: ShippingAndTimeRequest): Promise<ShippingAndTimeResponse> {
		return await this.webPrintAndShipPricingClient.getShippingAndTime(request);
	}

	getCategoriesCount(query: CategorySearch): Promise<number> {
		return this.printAndShipClient.getCategoriesCount(query);
	}

	getCategories(query: CategorySearch): Promise<PrintAndShipCategory[]> {
		return this.printAndShipClient.getCategories(query);
	}

	getSiteCategories(siteId: number): Promise<PrintAndShipCategory[]> {
		return this.webPrintAndShipClient.getSiteCategories(siteId);
	}

	getCategory(categoryId: number): Promise<PrintAndShipCategory> {
		return this.printAndShipClient.getCategory(categoryId);
	}

	addCategory(category: PrintAndShipCategory): Promise<number> {
		return this.printAndShipClient.addCategory(category);
	}

	updateCategory(category: PrintAndShipCategory): Promise<number> {
		return this.printAndShipClient.updateCategory(category);
	}
}
