// TODO if quantized, use simpler Int32 hashing?

var buffer = new ArrayBuffer(16),
  floats = new Float64Array(buffer),
  uints = new Uint32Array(buffer);
export default function (point) {
  floats[0] = point[0];
  floats[1] = point[1];
  var hash = uints[0] ^ uints[1];
  hash = hash << 5 ^ hash >> 7 ^ uints[2] ^ uints[3];
  return hash & 0x7fffffff;
}