import { Injectable } from '@angular/core';
import { BundlesClient, SiteBundle, BundleDisplay, GetBundlesRequest, UpdateBundleRequest,
    BundleContentResponse, BundleSortOrder
 } from '@taradel/admin-api-client';
import { HttpWithAuth } from 'app/http-with-auth';
import { environment } from 'environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class BundlesService {

	private readonly bundlesClient: BundlesClient;

	constructor(http: HttpWithAuth) {
		this.bundlesClient = new BundlesClient(environment.adminApiUrl, http);
	}
	async getDistributionRelatedBundles(siteId: number, distributionId: number): Promise<SiteBundle[]> {
		return await this.bundlesClient.getDistributionRelatedBundlesForAdmin(siteId, distributionId);
	}

	async getBundle(bundleId: number): Promise<BundleDisplay> {
		return await this.bundlesClient.getBundleDisplay(bundleId);
	}

	async searchForSiteBundlesCount(request: GetBundlesRequest): Promise<number> {
		return await this.bundlesClient.searchForSiteBundlesCount(request);
	}

	async searchForSiteBundles(request: GetBundlesRequest): Promise<BundleDisplay[]> {
		return await this.bundlesClient.searchForSiteBundles(request);
	}

	async createBundle(request: UpdateBundleRequest): Promise<number> {
		return await this.bundlesClient.createBundle(request);
	}

	async updateBundle(request: UpdateBundleRequest): Promise<number> {
		return await this.bundlesClient.updateBundle(request);
	}

	async deleteBundle(bundleId: number): Promise<number> {
		return await this.bundlesClient.deleteBundle(bundleId);
	}

	async updateBundleSortOrder(sortOrder: BundleSortOrder[]): Promise<void> {
		await this.bundlesClient.updateBundleSortOrder(sortOrder);
	}

	async getAvailableBundlesForSite(siteId: number): Promise<BundleDisplay[]> {
		return await this.bundlesClient.getAvailableBundlesForSite(siteId);
	}

	async createSiteBundle(siteBundle: SiteBundle): Promise<number> {
		return await this.bundlesClient.createSiteBundle(siteBundle);
	}

	async getBundleContent(bundleId: number): Promise<BundleContentResponse> {
		return await this.bundlesClient.getBundleContent(bundleId);
	}

	async deleteSiteBundle(siteId: number, bundleId: number): Promise<number> {
		return await this.bundlesClient.deleteSiteBundle(siteId, bundleId);
	}
}
