import { HttpWithAuth } from 'app/http-with-auth';
import { Injectable } from '@angular/core';
import { environment } from 'environment';
import {
	DeliveryDatesResponse, CartsClient as WebCartsClient,
	DeliveryDatesClient as WebDeliveryDatesClient, CouponClient as WebCouponClient,
	PersonalizedMailFieldGroup,
	FieldGroupsClient as WebFieldGroupsClient,
	OrderAttribution,
	GetCalendarRequest,
} from '@taradel/web-api-client';
import {
	AttributionClient, DeliveryDatesClient, FileParameter,
	ReceiptClient,
	OrdersClient,
	CustomerClient,
	Customer,
	CouponClient,
	Coupon,
	FileResponse,
	PDFReceiptClient,
	CustomerProfile,
	PaymentClient,
	ValidateCoupon,
	ArtOrientation,
	ShoppingClient,
	AdminQuoteFromCartQuery,
	ShoppingCart,
	TransferCartQuery,
	USelectInductionTurnaround,
	Calendar,
	GetCaCalendarRequest,
	GetInHomeDateRange,
	DropDeliveryRange,
	DeletePaymentProfileQuery,
	CreatePaymentProfileQuery
} from '@taradel/admin-api-client';
import { USelect } from './distributions.service';
import { lastValueFrom } from 'rxjs';

export let DirectMailProducts = [
	USelect.eddm,
	USelect.snapAdmail,
	USelect.b2B,
	USelect.buildPoliticalList,
	USelect.buildYourList,
	USelect.equifaxB2B,
	USelect.uploadPoliticalList,
	USelect.uploadYourList,
	USelect.printAndShip
];
export let PMProducts = [
	USelect.b2B,
	USelect.buildPoliticalList,
	USelect.buildYourList,
	USelect.equifaxB2B,
	USelect.uploadPoliticalList,
	USelect.uploadYourList
];
export let PrintProductTypes = [
	'EDDM',
	'SnapAdmail',
	'B2BMail',
	'AddressedList',
	'PoliticalMail',
	'EquifaxB2b'
];

export let ProductsWithDailyBudget = [
	USelect.displayAds,
	USelect.facebookAds,
	USelect.linkedinAds,
	USelect.hulu,
	USelect.instagram,
	USelect.spotify,
	USelect.tiktok,
	USelect.nextdoorAds
];

export let DigitalProducts = [
	USelect.displayAds,
	USelect.facebookAds,
	USelect.linkedinAds,
	USelect.targetedEmail,
	USelect.linkedinAds,
	USelect.hulu,
	USelect.instagram,
	USelect.spotify,
	USelect.tiktok,
	USelect.nextdoorAds
];
export let DigitalProductsTypes = [
	'Display Ads',
	'Facebook Ads',
	'LinkedIn Ads',
	'Targeted Emails'
];

export enum DaysOfWeek {
	Sunday = 0,
	Monday = 1,
	Tuesday = 2,
	Wednesday = 3,
	Thursday = 4,
	Friday = 5,
	Saturday = 6
}

@Injectable({
	providedIn: 'root'
})
export class SalesApiService {
	private readonly customerClient: CustomerClient;
	private readonly attributionClient: AttributionClient;
	private readonly deliveryDatesClient: DeliveryDatesClient;
	private readonly receiptClient: ReceiptClient;
	private readonly shoppingClient: ShoppingClient;
	private readonly ordersClient: OrdersClient;
	private readonly paymentClient: PaymentClient;
	private readonly couponClient: CouponClient;
	private readonly pdfReceiptClient: PDFReceiptClient;

	private readonly webDeliveryDatesClient: WebDeliveryDatesClient;
	private readonly webCartsClient: WebCartsClient;
	private readonly webCouponClient: WebCouponClient;
	private readonly webFieldGroupsClient: WebFieldGroupsClient;

	constructor(http: HttpWithAuth) {
		this.customerClient = new CustomerClient(environment.adminApiUrl, http);
		this.attributionClient = new AttributionClient(environment.adminApiUrl, http);
		this.deliveryDatesClient = new DeliveryDatesClient(environment.adminApiUrl, http);
		this.receiptClient = new ReceiptClient(environment.adminApiUrl, http);
		this.shoppingClient = new ShoppingClient(environment.adminApiUrl, http);
		this.ordersClient = new OrdersClient(environment.adminApiUrl, http);
		this.paymentClient = new PaymentClient(environment.adminApiUrl, http);
		this.couponClient = new CouponClient(environment.adminApiUrl, http);
		this.pdfReceiptClient = new PDFReceiptClient(environment.adminApiUrl, http);

		this.webDeliveryDatesClient = new WebDeliveryDatesClient(environment.webApiUrl, http);
		this.webCartsClient = new WebCartsClient(environment.webApiUrl, http);
		this.webCouponClient = new WebCouponClient(environment.webApiUrl, http);
		this.webFieldGroupsClient = new WebFieldGroupsClient(environment.webApiUrl, http);
	}

	getOrderAttribution(orderId: number): Promise<OrderAttribution> {
		return this.attributionClient.getOrderAttribution(orderId);
	}

	exportAttributionPdf(orderId: number, reportData: string): Promise<FileResponse | null> {
		return this.attributionClient.exportDashboardToPdf(orderId, reportData);
	}

	getEddmDeliveryDates(): Promise<DeliveryDatesResponse> {
		return this.webDeliveryDatesClient.getEddmDeliveryDates();
	}

	getPmDeliveryDates(): Promise<DeliveryDatesResponse> {
		return this.webDeliveryDatesClient.getPmDeliveryDates();
	}

	async getUSelectInductionTurnaround(uSelectId: number) : Promise<USelectInductionTurnaround> {
		return await this.webDeliveryDatesClient.getUSelectInductionTurnaround(uSelectId);
	}

	getUsDates(query: GetCalendarRequest): Promise<Calendar> {
		return this.webDeliveryDatesClient.getUsCalendar(query);
	}

	getInHomeDateRange(query: GetInHomeDateRange): Promise<DropDeliveryRange[]> {
		return this.ordersClient.getInHomeDateRange(query);
	}

	getCaDates(customerId: number, query: GetCaCalendarRequest): Promise<Calendar> {
		return this.deliveryDatesClient.getCaCalendar(customerId, query);
	}

	uploadTemporaryFile(uploadedFile: FileParameter): Promise<any> {
		return this.webCartsClient.uploadTemporaryFile(uploadedFile);
	}

	async generateQuote(customerId: number, siteId: number, billingAddressId: number, couponCode: string, productDiscounts?: { [key: string]: number }): Promise<string> {
		return await this.shoppingClient.quoteFromCustomerCart(new AdminQuoteFromCartQuery({
			customerId,
			siteId,
			billingAddressId,
			couponCode,
			productDiscounts
		}));
	}

	async emailCart(siteId: number, customerId: number): Promise<boolean> {
		return await this.receiptClient.emailShoppingCart(siteId, customerId);
	}

	async emailQuotes(quoteIds: string[]): Promise<void> {
		await this.receiptClient.emailShoppingQuotes(quoteIds);
	}

	async getCustomerProfile(customerId: number): Promise<CustomerProfile> {
		return await this.paymentClient.getCustomerProfile(customerId);
	}

	async createCustomerPaymentProfile(query: CreatePaymentProfileQuery): Promise<void> {
		await this.paymentClient.createPaymentProfile(query);
	}

	async deleteCustomerProfile(customerId: number, customerPaymentProfileId: string): Promise<void> {
		await this.paymentClient.deletePaymentProfile(new DeletePaymentProfileQuery({
			customerId,
			paymentProfileId: customerPaymentProfileId
		}));
	}

	hasActiveCoupons(siteId: number): Promise<boolean> {
		return this.webCouponClient.hasActiveCoupons(siteId);
	}

	validateShoppingCartCoupon(customerId: number, validateCoupon: ValidateCoupon): Promise<Coupon> {
		return this.couponClient.validateCustomerShoppingCartCoupon(customerId, validateCoupon);
	}

	async createCoupon(coupon: Coupon): Promise<number> {
		return await this.couponClient.createCoupon(coupon);
	}

	getCustomer(customerId: number): Promise<Customer> {
		return this.customerClient.getCustomer(customerId);
	}

	async getCustomerShoppingCarts(customerId: number): Promise<ShoppingCart[]> {
		return await this.shoppingClient.getShoppingCarts(customerId);
	}

	async transferShoppingCart(query: TransferCartQuery) {
		await this.shoppingClient.transferShoppingCart(query);
	}

	getUSelectFieldGroups(uSelectId: number): Promise<{ [id: number]: PersonalizedMailFieldGroup }> {
		return this.webFieldGroupsClient.getUSelectFieldGroups(uSelectId);
	}

	async artOrientation(artOrientation: ArtOrientation): Promise<FileResponse | null> {
		return await this.ordersClient.artOrientation(artOrientation, artOrientation.orderItemId.toString());
	}

	async uploadFrontDesign(orderItemId: number, uploadedFile: FileParameter): Promise<FileResponse | null> {
		return await this.ordersClient.uploadFrontDesign(orderItemId, uploadedFile);
	}

	async uploadBackDesign(orderItemId: number, uploadedFile: FileParameter): Promise<FileResponse | null> {
		return await this.ordersClient.uploadBackDesign(orderItemId, uploadedFile);
	}

	async downloadFrontDesign(orderItemId: number): Promise<FileResponse | null> {
		return await this.ordersClient.downloadFrontDesign(orderItemId);
	}

	async downloadBackDesign(orderItemId: number): Promise<FileResponse | null> {
		return await this.ordersClient.downloadBackDesign(orderItemId);
	}

	async getPdf(orderId: number): Promise<void> {
		const fileResponse = await this.pdfReceiptClient.adminGet(orderId);
		const downloadURL = window.URL.createObjectURL(fileResponse?.data!);
		const link = document.createElement('a');
		link.href = downloadURL;
		link.download = `Order-${orderId}.pdf`;
		link.click();
	}
}
